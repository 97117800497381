import * as Yup from 'yup';

export const projectFormSchema = Yup.object({
  title: Yup.string()
    .min(5, '"Project Title" must be 5 characters at minimum')
    .max(100, '"Project Title" must be 100 characters or less')
    .required('"Project Title" is required'),
  projectPlan: Yup.string(),
  risks: Yup.string(),
  opportunities: Yup.string(),
  resources: Yup.string(),
});
