import React, { useCallback, useMemo } from 'react';
import { normalizeYearsData } from '../../../constants/functions';
// import { currentYear, currentMonth, daysInMonth, currentDate } from '../../../constants/index';
import { selectDate, selectYear, selectMonth, selectDaysInMonth } from '../../../constants/functions';
const LetterP = ({ huddleBoard, selectedDate, setSelectedDate }) => {
  const data = useMemo(() => {
    return normalizeYearsData(huddleBoard?.data, selectedDate)[0].years[selectYear(selectedDate)][selectMonth(selectedDate)];
  }, [huddleBoard, selectedDate]);
  
  const renderRow = useCallback(
    (startIdx, endIdx, addEmptyDiv = false) => (
      <div className="row">
        {data.slice(startIdx, endIdx).map((el, idx) => {
          const globalIdx = startIdx + idx;

          return (
            <React.Fragment key={globalIdx}>
              {/* Special case for index 14 */}
              {globalIdx === 14 && (
                <>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {globalIdx === 18 && (
                <>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                </>
              )}              
              
              {/* Regular div */}
              <div
                className={` indicator ${
                  globalIdx === (Number(selectDate(selectedDate).getDate()) - 1) ? 'today' : ''
                }`}
                onClick={() => setSelectedDate(new Date(selectDate(selectedDate).setDate(globalIdx + 1)))}
                style={
                  el === 'true'
                    ? { backgroundColor: '#5BF98A' }
                    : el === 'false'
                    ? { backgroundColor: '#FC9797' }
                    : {}
                }
              >
                {globalIdx + 1}
              </div>
            </React.Fragment>
          );
        })}
        {addEmptyDiv && (
          <div className="row-indicator indicator" style={{ backgroundColor: '#D9E4FF' }} />
        )}
      </div>
    ),
    [data, selectedDate],
  );
  return (
    <>
      {renderRow(0, 6)}
      {renderRow(6, 12)}
      {renderRow(12, 16)}
      {renderRow(16, 20)}
      {renderRow(20, 26)}
      {renderRow(26, 28)}
      {selectDaysInMonth(selectedDate) === 29 && (
        <>
          {renderRow(28, 29, true)}
          <div className="row">
            <div className="row-indicator indicator" style={{ backgroundColor: '#D9E4FF' }} />
            <div className="row-indicator indicator" style={{ backgroundColor: '#D9E4FF' }} />
          </div>
        </>
      )}
      {selectDaysInMonth(selectedDate) === 30 && (
        <>
          {renderRow(28, 30)}
          <div className="row">
            <div className="row-indicator indicator" style={{ backgroundColor: '#D9E4FF' }} />
            <div className="row-indicator indicator" style={{ backgroundColor: '#D9E4FF' }} />
          </div>
        </>
      )}
      {selectDaysInMonth(selectedDate) === 31 && (
        <>
          {renderRow(28, 30)}
          {renderRow(30, 31, true)}
        </>
      )}
    </>
  );
};
export default LetterP;
