import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { updateHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { formatDate } from '../../../constants/functions';

const WeeklyPriorities = ({ huddleBoard, selectedDate, updateHuddleBoard, labels }) => {
  
  const [formattedDate, setFormattedDate] = useState(formatDate(selectedDate.toISOString()));
  
  const [data, setData] = useState(
    huddleBoard?.data.find((item) => item.date === formattedDate) || {},
  );

  const [currP1Color, setCurrP1Color] = useState(
    data?.p1State,
  );

  const [currP2Color, setCurrP2Color] = useState(
    data?.p2State,
  );

  const [currP3Color, setCurrP3Color] = useState(
    data?.p3State,
  );
    
  const [formVisibility, setFormVisibility] = useState(Array(3).fill(false));
  
  
  const formik = useFormik({
    initialValues: {
      p1Description: data.p1Description || '',
      p1Status: data.p1Status || 'no-data',
      p2Description: data.p2Description || '',
      p2Status: data.p2Status || 'no-data',
      p3Description: data.p3Description || '',
      p3Status: data.p3Status || 'no-data'
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      p1Description: Yup.string().optional().max(178),
      p1Status: Yup.string().optional(),
      p2Description: Yup.string().optional().max(178),
      p2Status: Yup.string().optional(),
      p3Description: Yup.string().optional().max(178),
      p3Status: Yup.string().optional()
    }),
    onSubmit: (values, { resetForm }) => {
      formik.setFieldValue('p1Status', currP1Color);
      formik.setFieldValue('p2Status', currP2Color);
      formik.setFieldValue('p3Status', currP3Color);
      if (!huddleBoard?.data?.length) {
        updateHuddleBoard(
          huddleBoard.id,          
          [{ ...values, date: formattedDate }],
          huddleBoard,
          'Custom',
          selectedDate,
        );
      } else if (huddleBoard.data.find((el) => el.date === formattedDate)) {
        updateHuddleBoard(
          huddleBoard.id,
          [
            ...huddleBoard.data.filter((el) => el.date !== formattedDate),            
            { ...values, date: formattedDate },
          ],
          huddleBoard,
          'Custom',
          selectedDate,
        );
      } else {
        updateHuddleBoard(
          huddleBoard.id,
          [
            ...huddleBoard.data,            
            { ...values, date: formattedDate },
          ],
          huddleBoard,
          'Custom',
          selectedDate,
        );
      }
      setFormVisibility(Array(3).fill(false));
      resetForm();
    },
  });
  
  useEffect(() => {
    setFormattedDate(formatDate(selectedDate.toISOString()));
    setData(huddleBoard?.data.find((item) => item.date === formattedDate) || {});
    setCurrP1Color(data?.p1Status);
    setCurrP2Color(data?.p2Status);
    setCurrP3Color(data?.p3Status);
  }, [huddleBoard, selectedDate, labels, formattedDate]);
  
  const handleFormVisibilityToggle = (key) => {
    const newVisibility = [...formVisibility];
    if (!newVisibility[key]) {
      formVisibility.forEach((_, index) => {
        if (index !== key) {
          newVisibility[index] = false;
          switch (index) {
            case 0:
              formik.setFieldValue('p1Description', data?.p1Description || '');
              break;
            case 1:
              formik.setFieldValue('p2Description', data?.p2Description || '');
              break;
            case 2:
              formik.setFieldValue('p3Description', data?.p3Description || '');
              break;            
            default:
              break;
          }
        }
      });
    } else {
      switch (key) {
        case 0:
          formik.setFieldValue('p1Description', data?.p1Description || '');
          break;
        case 1:
          formik.setFieldValue('p2Description', data?.p2Description || '');
          break;
        case 2:
          formik.setFieldValue('p3Description', data?.p3Description || '');
          break;        
        default:
          break;
      }
    }
    newVisibility[key] = !newVisibility[key];
    setFormVisibility(newVisibility);
  };

  const handleStatusButtonClick = (color, buttonKey, priority) => {
          
    if (priority === 1) {
      formik.setFieldValue('p1Status', color);
      setCurrP1Color(color);
    } else if (priority === 2) {
      formik.setFieldValue('p2Status', color);
      setCurrP2Color(color);
    } else if (priority === 3) {
      formik.setFieldValue('p3Status', color);
      setCurrP3Color(color);
    }
    
    for (let i = 0; i <= 2; i++) {
      handleFormVisibilityToggle(i);
    }
    formik.submitForm();
  };

  return (
    <>
      <form className="form__content form weekly-priorities-form" onSubmit={formik.handleSubmit}>
        <table className="form__table">
          <thead>
            <tr>
              <th className='wp_priority'>Priority</th>
              <th className='wp_description'>Description</th>
              <th className='wp_status'>Status</th>
            </tr>
          </thead>
          <tbody>
            
            {/* Priority 1 */}
            <tr className={`priority-status-row ${data?.p1Status === "Complete" ? 'dark-accept' : data?.p1Status === "Green" ? 'accept' : data?.p1Status === "Yellow" ? 'yellow' : data?.p1Status === "Red" ? 'danger' : ''}`}>
              <td className="descriptor wp_priority">1</td>
              {/* priority description */}
              <td className="wp_description">
                {!formVisibility[0] ? (
                  <div className="input-row">
                    <div>{data?.p1Description}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(0)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="3"
                      name="p1Description"
                      id="p1Description"
                      onChange={formik.handleChange}
                      value={formik.values.p1Description}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(0)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
                {formik.touched.p1Description && formik.errors.p1Description && (
                  <div className="error">{formik.errors.p1Description}</div>
                )}
              </td>
              {/* priority status */}
              <td className='wp_status'>
                <button
                  type="button"
                  className={`button status-button ${data?.p1Status === 'Complete' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[0], 'p1Button0', 1);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p1Status === 'Green' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[1], 'p1Button1', 1);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p1Status === 'Yellow' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[2], 'p1Button2', 1);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p1Status === 'Red' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[3], 'p1Button3', 1);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p1Status === 'no-data' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick('no-data', 'p1Button4', 1);
                  }}
                ></button>
              </td>
              
            </tr>
            
            {/* Priority 2 */}
            <tr className={`priority-status-row ${data?.p2Status === "Complete" ? 'dark-accept' : data?.p2Status === "Green" ? 'accept' : data?.p2Status === "Yellow" ? 'yellow' : data?.p2Status === "Red" ? 'danger' : ''}`}>
              <td className="descriptor wp_priority">2</td>
              {/* priority description */}
              <td className='wp_description'>
                {!formVisibility[1] ? (
                  <div className="input-row">
                    <div>{data?.p2Description}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(1)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="3"
                      name="p2Description"
                      id="p2Description"
                      onChange={formik.handleChange}
                      value={formik.values.p2Description}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(1)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
                {formik.touched.p2Description && formik.errors.p2Description && (
                  <div className="error">{formik.errors.p2Description}</div>
                )}
              </td>
              {/* priority status */}
              <td className='wp_status'>
                <button
                  type="button"
                  className={`button status-button ${data?.p2Status === 'Complete' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[0], 'p2Button0', 2);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p2Status === 'Green' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[1], 'p2Button1', 2);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p2Status === 'Yellow' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[2], 'p2Button2', 2);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p2Status === 'Red' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[3], 'p2Button3', 2);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p2Status === 'no-data' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick('no-data', 'p2Button4', 2);
                  }}
                ></button>
              </td>
              
            </tr>

            {/* Priority 3 */}
            <tr className={`priority-status-row ${data?.p3Status === "Complete" ? 'dark-accept' : data?.p3Status === "Green" ? 'accept' : data?.p3Status === "Yellow" ? 'yellow' : data?.p3Status === "Red" ? 'danger' : ''}`}>
              <td className="descriptor wp_priority">3</td>
              {/* priority description */}
              <td className='wp_description'>
                {!formVisibility[2] ? (
                  <div className="input-row">
                    <div>{data?.p3Description}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(2)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="3"
                      name="p3Description"
                      id="p3Description"
                      onChange={formik.handleChange}
                      value={formik.values.p3Description}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(2)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
                {formik.touched.p3Description && formik.errors.p3Description && (
                  <div className="error">{formik.errors.p3Description}</div>
                )}
              </td>
              {/* priority status */}
              <td className='wp_status'>
                <button
                  type="button"
                  className={`button status-button ${data?.p3Status === 'Complete' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[0], 'p3Button0', 3);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p3Status === 'Green' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[1], 'p3Button1', 3);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p3Status === 'Yellow' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[2], 'p3Button2', 3);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p3Status === 'Red' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[3], 'p3Button3', 3);
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.p3Status === 'no-data' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick('no-data', 'p3Button4', 3);
                  }}
                ></button>
              </td>
              
            </tr>
            
          </tbody>
        </table>
      </form>
    </>
  );
};

export default connect(null, { updateHuddleBoard })(WeeklyPriorities);
