// import React, { useState, useEffect } from 'react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import { deleteDepartment, editDepartment, clearDepartmentError, getUserForDepartment } from '../../store/actions/departmentActions';


import './styles.css';

const Department = ({ department, auth, deleteDepartment, project, editDepartment, clearDepartmentError, getUserForDepartment }) => {
  // const [isEdit, setIsEdit] = useState(false);
  // const [addUserError, setAddUserError] = useState(null);
  

  const handleDelete = (e, id) => {
    e.preventDefault();
    // if (!isEdit) {
      deleteDepartment(id);
    // }
  };

  const checkPermissions = () => {
    if (auth.isAuthenticated && (auth.me.id === department.project.user || auth.me.role === 'SUPERADMIN' || project.podMasters.some(pm => pm.id === auth.me.id))) {
      return true;
    }
    return false;
  }
  
  return (
    <div className={department.isLoading ? 'department loader' : 'department'}>    
      {/* <p>{departmentProfile ? departmentProfile.name : null}</p> */}

      <div className="department-list-item">
        <div className="department-row">
          <div className="department-color-box" style={{ backgroundColor: department.color }}></div>
          <div className="department-name">{department.name}</div>
          <div className="department-tags">
            <div className="department-user-count">{department.users.length} users &nbsp;</div>
            {department.viewOnly ? <div className="view-only">View Only &nbsp;</div> : null}
            {department.external ? <div className="external">External &nbsp;</div> : null}
          </div>
        </div>
        
      </div>
      {checkPermissions() && (
        <div className="department-actions">
          <Link to={`/project/department/edit/${department.id}`} className="btn department-btn">
            <FontAwesomeIcon icon={faPen} /> Edit
          </Link>
          <button onClick={(event) => handleDelete(event, department.id)} type="button" className="btn department-btn">
            <FontAwesomeIcon icon={faTrash} /> Delete
          </button>
        </div>
      )}
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,  
  // departments: state.department,
});


export default connect(mapStateToProps, { deleteDepartment, editDepartment, clearDepartmentError, getUserForDepartment })(Department);
