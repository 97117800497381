import React, { useMemo } from 'react';
import LetterLayout from './LettersLayout/LetterLayout';
import CustomLayout from './CustomLayout/CustomLayout';
import DailyLayout from './DailyLayout/DailyLayout';
import WeeklyLayout from './WeeklyLayout/WeeklyLayout';
import { letterOptions, dailyOptions, weeklyOptions } from '../../constants/index';

const HuddleBoardContent = ({ title, huddleBoard, labels }) => {
  const isLetterComponent = useMemo(() => {
    return letterOptions.includes(title);
  }, [title]);

  const isDailyComponent = useMemo(() => {
    return dailyOptions.includes(title);
  }, [title]);

  const isWeeklyComponent = useMemo(() => {
    return weeklyOptions.includes(title);
  }, [title]);

  return (
    <div className="huddle-board-content">
      {isLetterComponent ? (
        <LetterLayout title={title} huddleBoard={huddleBoard} labels={labels} />
      ) : isDailyComponent ? (
        <DailyLayout title={title} huddleBoard={huddleBoard} labels={labels}/>
      ) : isWeeklyComponent ? (
        <WeeklyLayout title={title} huddleBoard={huddleBoard} labels={labels} />
      ) : (
        <CustomLayout title={title} huddleBoard={huddleBoard} />
      )}
    </div>
  );
};

export default HuddleBoardContent;
