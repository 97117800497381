import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Department from '../Department/Department';
import Loader from '../Loader/Loader';

import { getDepartments } from '../../store/actions/departmentActions';
import './styles.css';


const DepartmentList = ({ getDepartments, projectId, project, department: { departments, isLoading, error, departmentProfile }}) => {
  // const DepartmentList = ({ getDepartments, projectId, department: { departments, isLoading, error }}) => {
  useEffect(() => {
    getDepartments(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="department-list">      
      
      {error && <div className="error-center">{error}</div>}
      <div className="list">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {departments.map((department, index) => {
              return <Department key={index} department={department} departmentProfile={departmentProfile} project={project} />;              
            })}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  department: state.department,
  // departmentProfile: state.departmentProfile,
});

export default connect(mapStateToProps, { getDepartments })(DepartmentList);
