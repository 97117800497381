import * as Yup from 'yup';

export const departmentFormSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Department Name" must be 2 characters at minimum')
    .max(30, 'Department Name" must be 30 characters or less')
    .required('Required'),
  color: Yup.string()
    .required('Required'),
  viewOnly: Yup.boolean(),
  external: Yup.boolean(),
});
