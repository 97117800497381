import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Layout from '../../layout/Layout';
// import MessageList from '../../components/MessageList/MessageList';
import ActiveProjectList from '../../components/ActiveProjectList/ActiveProjectList';
import ArchivedProjectList from '../../components/ArchivedProjectList/ArchivedProjectList';
import ProjectForm from '../../components/ProjectForm/ProjectForm';
import requireAuth from '../../hoc/requireAuth';

import './styles.css';

const Archive = () => {  
  return (
    <Layout>
      <div className="archive-page">
        <h1>Dashboard</h1>        
        <div className="project-list-container">
          {/* <div className="project-list">            
            <ActiveProjectList />
            <ProjectForm />            
          </div> */}
          <div className="project-list">            
            <ArchivedProjectList />
          </div>
        </div>
        
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,  
});

export default compose(requireAuth, connect(mapStateToProps))(Archive);
