import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { updateHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { formatDate } from '../../../constants/functions';

const SponsorReport = ({ huddleBoard, selectedDate, updateHuddleBoard, labels }) => {
  
  // const formattedDate = useMemo(() => formatDate(selectedDate.toISOString()), [selectedDate]);

  const [formattedDate, setFormattedDate] = useState(formatDate(selectedDate.toISOString()));
  
  const [currColor, setCurrColor] = useState(
    huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue,
  );

  const [data, setData] = useState(
    huddleBoard?.data.find((item) => item.date === formattedDate) || {},
  );
  
  // const data = useMemo(() => {
  //   return huddleBoard?.data.find((item) => item.date === formattedDate) || {};
  // }, [huddleBoard, formattedDate]);
  
  const [formVisibility, setFormVisibility] = useState(Array(7).fill(false));
  
  // const [buttonStates, setButtonStates] = useState({
  //   button1:
  //     huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === labels[0],
  //   button2:
  //     huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === labels[1],
  //   button3:
  //     huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === labels[2],
  //   button4:
  //     huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === 'no-data',
  // });
  // console.log(currColor);
  // console.log(
  //   huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === labels[2],
  // );
  /*  useEffect(() => {
    const currentData = huddleBoard?.data.find((item) => item.date === formattedDate);
    setButtonStates({
      button1: currentData?.statusValue === labels[0],
      button2: currentData?.statusValue === labels[1],
      button3: currentData?.statusValue === labels[2],
      button4: currentData?.statusValue === 'no-data',
    });
  }, [huddleBoard?.data, formattedDate, labels]);
 */
  
  const formik = useFormik({
    initialValues: {
      statusValue: data.statusValue || 'no-data',
      status: data.status || '',
      risks: data.risks || '',
      risksMitigation: data.risksMitigation || '',
      clientCommunication: data.clientCommunication || '',
      clientCommunicationMitigation: data.clientCommunicationMitigation || '',
      goldenMoments: data.goldenMoments || '',
      goldenMomentsMitigation: data.goldenMomentsMitigation || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      statusValue: Yup.string().optional(),
      status: Yup.string().optional().max(80),
      risksMitigation: Yup.string().optional().max(80),
      clientCommunication: Yup.string().optional().max(80),
      clientCommunicationMitigation: Yup.string().optional().max(80),
      goldenMoments: Yup.string().optional().max(80),
      goldenMomentsMitigation: Yup.string().optional().max(80),
    }),
    onSubmit: (values, { resetForm }) => {
      formik.setFieldValue('statusValue', currColor);
      if (!huddleBoard?.data?.length) {
        updateHuddleBoard(
          huddleBoard.id,
          [{ ...values, statusValue: values.statusValue || 'no-data', date: formattedDate }],
          huddleBoard,
          'Custom',
          selectedDate,
        );
      } else if (huddleBoard.data.find((el) => el.date === formattedDate)) {
        updateHuddleBoard(
          huddleBoard.id,
          [
            ...huddleBoard.data.filter((el) => el.date !== formattedDate),
            { ...values, statusValue: values.statusValue || 'no-data', date: formattedDate },
          ],
          huddleBoard,
          'Custom',
          selectedDate,
        );
      } else {
        updateHuddleBoard(
          huddleBoard.id,
          [
            ...huddleBoard.data,
            { ...values, statusValue: values.statusValue || 'no-data', date: formattedDate },
          ],
          huddleBoard,
          'Custom',
          selectedDate,
        );
      }
      setFormVisibility(Array(7).fill(false));
      resetForm();
    },
  });
  
  useEffect(() => {
    setFormattedDate(formatDate(selectedDate.toISOString()));
    setData(huddleBoard?.data.find((item) => item.date === formattedDate) || {}); 
    // setButtonStates({
    //   button1:
    //     huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === labels[0],
    //   button2:
    //     huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === labels[1],
    //   button3:
    //     huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === labels[2],
    //   button4:
    //     huddleBoard?.data.find((item) => item.date === formattedDate)?.statusValue === 'no-data',
    // });    
  }, [huddleBoard, formattedDate, labels, selectedDate]);
  
  const handleFormVisibilityToggle = (key) => {
    const newVisibility = [...formVisibility];
    if (!newVisibility[key]) {
      formVisibility.forEach((_, index) => {
        if (index !== key) {
          newVisibility[index] = false;
          switch (index) {
            case 0:
              formik.setFieldValue('status', data?.status || '');
              break;
            case 1:
              formik.setFieldValue('risks', data?.risks || '');
              break;
            case 2:
              formik.setFieldValue('risksMitigation', data?.risksMitigation || '');
              break;
            case 3:
              formik.setFieldValue('clientCommunication', data?.clientCommunication || '');
              break;
            case 4:
              formik.setFieldValue('clientCommunicationMitigation', data?.clientCommunicationMitigation || '');
              break;
            case 5:
              formik.setFieldValue('goldenMoments', data?.goldenMoments || '');
              break;
            case 6:
              formik.setFieldValue('goldenMomentsMitigation', data?.goldenMomentsMitigation || '');
              break;
            default:
              break;
          }
        }
      });
    } else {
      switch (key) {
        case 0:
          formik.setFieldValue('status', data?.status || '');
          break;
        case 1:
          formik.setFieldValue('risks', data?.risks || '');
          break;
        case 2:
          formik.setFieldValue('risksMitigation', data?.risksMitigation || '');
          break;
        case 3:
          formik.setFieldValue('clientCommunication', data?.clientCommunication || '');
          break;
        case 4:
          formik.setFieldValue('clientCommunicationMitigation', data?.clientCommunicationMitigation || '');
          break;
        case 5:
          formik.setFieldValue('goldenMoments', data?.goldenMoments || '');
          break;
        case 6:
          formik.setFieldValue('goldenMomentsMitigation', data?.goldenMomentsMitigation || '');
          break;
        default:
          break;
      }
    }
    newVisibility[key] = !newVisibility[key];
    setFormVisibility(newVisibility);
  };

  const handleStatusButtonClick = (color, buttonKey) => {
    formik.setFieldValue('statusValue', color);
    setCurrColor(color);
    // setButtonStates({
    //   button1: buttonKey === 'button1',
    //   button2: buttonKey === 'button2',
    //   button3: buttonKey === 'button3',
    //   button4: buttonKey === 'button4',
    // });
    for (let i = 0; i <= 6; i++) {
      handleFormVisibilityToggle(i);
    }
    formik.submitForm();
  };

  return (
    <>
      <form className="form__content form sponsor-report-form" onSubmit={formik.handleSubmit}>
        <table className="form__table">
          <thead>
            <tr>
              <th className="descriptor"></th>
              <th>Description / Key Achievements</th>
              <th>Impact / Mitigation / Action Plans / Request for Support</th>
            </tr>
          </thead>
          <tbody>
            
            {/* Overall Status */}
            <tr>
              <td className="descriptor">Overall Status</td>
              <td>
                <button
                  type="button"
                  className={`button status-button ${data?.statusValue === 'Green' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[0], 'button1');
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.statusValue === 'Yellow' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[1], 'button2');
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.statusValue === 'Red' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick(labels[2], 'button3');
                  }}
                ></button>
                <button
                  type="button"
                  className={`button status-button ${data?.statusValue === 'no-data' ? 'clicked' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStatusButtonClick('no-data', 'button4');
                  }}
                ></button>
              </td>
              <td>
                {!formVisibility[0] ? (
                  <div className="input-row">
                    <div>{data?.status}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(0)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="4"
                      name="status"
                      id="status"
                      onChange={formik.handleChange}
                      value={formik.values.status}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(0)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
                {formik.touched.status && formik.errors.status && (
                  <div className="error">{formik.errors.status}</div>
                )}
              </td>
            </tr>
            
            {/* Risks / Escalations */}
            <tr>
              <td className="descriptor">Risks /<br/>&nbsp; Escalations</td>
              <td>
                {!formVisibility[1] ? (
                  <div className="input-row">
                    <div>{data?.risks}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(1)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="4"
                      name="risks"
                      id="risks"
                      onChange={formik.handleChange}
                      value={formik.values.risks}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(1)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
              </td>
              <td>
                {!formVisibility[2] ? (
                  <div className="input-row">
                    <div>{data?.risksMitigation}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(2)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="4"
                      name="risksMitigation"
                      id="risksMitigation"
                      onChange={formik.handleChange}
                      value={formik.values.risksMitigation}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(2)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
              </td>
            </tr>

            {/* Client / Communication */}
            <tr>
              <td className="descriptor">Client /<br/>&nbsp; Communication</td>
              <td>
                {!formVisibility[3] ? (
                  <div className="input-row">
                    <div>{data?.clientCommunication}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(3)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="4"
                      name="clientCommunication"
                      id="clientCommunication"
                      onChange={formik.handleChange}
                      value={formik.values.clientCommunication}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(3)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
              </td>
              <td>
                {!formVisibility[4] ? (
                  <div className="input-row">
                    <div>{data?.clientCommunicationMitigation}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(4)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="4"
                      name="clientCommunicationMitigation"
                      id="clientCommunicationMitigation"
                      onChange={formik.handleChange}
                      value={formik.values.clientCommunicationMitigation}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(4)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
              </td>
            </tr>
            
            {/* Golden Moments */}
            <tr>
              <td className="descriptor">Golden Moments</td>
              <td>
                {!formVisibility[5] ? (
                  <div className="input-row">
                    <div>{data?.goldenMoments}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(5)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="4"
                      name="goldenMoments"
                      id="goldenMoments"
                      onChange={formik.handleChange}
                      value={formik.values.goldenMoments}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(5)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
              </td>
              <td>
                {!formVisibility[6] ? (
                  <div className="input-row">
                    <div>{data?.goldenMomentsMitigation}</div>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="input-edit-icon"
                      onClick={() => handleFormVisibilityToggle(6)}
                    />
                  </div>
                ) : (
                  <>
                    <textarea
                      rows="4"
                      name="goldenMomentsMitigation"
                      id="goldenMomentsMitigation"
                      onChange={formik.handleChange}
                      value={formik.values.goldenMomentsMitigation}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-row-controls flex-center">
                      <button type="submit" className="edit-submit-button">
                        <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                      </button>
                      <button
                        type="button"
                        className="edit-cancel-button"
                        onClick={() => handleFormVisibilityToggle(6)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                      </button>
                    </div>
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </>
  );
};

export default connect(null, { updateHuddleBoard })(SponsorReport);
