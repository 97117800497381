import React, { useEffect, useState, useMemo } from 'react';
import MetricData from './MetricData';
import StatusCard from './StatusCard/StatusCard';
import { month } from '../../../constants/index';
import { normalizeYearsData } from '../../../constants/functions';
import { Bar } from 'react-chartjs-2';
import Loader from '../../Loader/Loader';
import Chart from 'chart.js/auto';
import './Metrica.css';

const MetricLetters = ({ huddleBoard, title, updateHuddleBoard, labels, selectedDate }) => {
  const currentDate = useMemo(() => selectedDate || new Date(), [selectedDate]);
  const currentYear = useMemo(() => currentDate.getFullYear().toString(), [currentDate]);
  // const currentMonth = useMemo(() => month[currentDate.getMonth()], [currentDate]);

  // const lastDayOfMonth = useMemo(
  //   () => new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  //   [currentDate],
  // );
  // const daysInMonth = useMemo(() => lastDayOfMonth.getDate(), [lastDayOfMonth]);

  // const noDataMonth = {
  //   [currentMonth]: Array(daysInMonth).fill('no-data'),
  // };

  // const data = useMemo(() => {
  //   return normalizeYearsData(huddleBoard?.data)[0].years[currentYear][currentMonth];
  // }, [huddleBoard, currentYear, currentMonth]);
  //Chart
  const formatChartData = (huddleBoardData) => {
    if (huddleBoardData) {
      // const data = huddleBoardData?.data[0]?.years?.['2024'] || {};
      const data = normalizeYearsData(huddleBoard?.data, selectedDate)[0].years[currentYear];
      // console.log(data);

      // Count values for each month
      let counts = month.map((mon) => {
        let days = data[mon] || [];
        return {
          mon,
          true: days.filter((day) => day === 'true').length,
          false: days.filter((day) => day === 'false').length,
          noData: days.filter((day) => day === 'no-data').length,
        };
      });

      // Prepare chart data
      let newChartData = {
        labels: month,
        datasets: [
          {
            label: labels[0],
            data: counts.map((item) => item.true),
            backgroundColor: '#4CAF50',
          },
          {
            label: labels[1],
            data: counts.map((item) => item.false),
            backgroundColor: '#FF7043',
          },
          {
            label: 'No Data',
            data: counts.map((item) => item.noData),
            backgroundColor: '#D9E4FF',
          },
        ],
      };

      // Update state to re-render the chart
      return newChartData;
    }
  };

  /* if (isLoading) {
    return <Loader />;
  }

  if (huddleBoardError) {
    return <div>Error: {huddleBoardError}</div>;
  } */

  return (
    <>
      <div className="huddle-board-content-metric">
        <div className="control-metrics-data">
          <h3>Selected month's metrics</h3>
          <MetricData huddleBoard={huddleBoard} labels={labels} title={title} selectedDate={selectedDate} />
        </div>
        <div
          className=" huddle-board-content-graph chart-container"
          // style={{ height: '400px', width: '100%' }}
        >
          {huddleBoard ? (
            <Bar
              data={formatChartData(huddleBoard)}
              options={{
                plugins: {
                  legend: {
                    position: 'top',
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                    beginAtZero: true,
                  },
                },
              }}
            />
          ) : (
            <div>Loading chart...</div>
          )}
        </div>
        <div className="huddle-board-content-controls">
          <StatusCard
            updateHuddleBoard={updateHuddleBoard}
            huddleBoard={huddleBoard}
            title={title}
            labels={labels.slice(0, 2)}
            selectedDate={selectedDate}
          />
        </div>
      </div>
    </>
  );
};
export default MetricLetters;
