import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Project from '../Project/Project';
import Loader from '../Loader/Loader';

import { getProjects } from '../../store/actions/projectActions';
import './styles.css';

const ArchivedProjectList = ({ getProjects, project: { projects, isLoading, error } }) => {
  useEffect(() => {
    getProjects(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="project-list">
      <h2>Archived Projects {' ('}
              {/* <Link className="bold" to="/dashboard"> */}
              <Link className="header-link" to="/">
                View Active
              </Link>{'):'}</h2>
      {error && <div className="error-center">{error}</div>}
      <div className="list">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {projects.map((project, index) => {
              return <Project key={index} project={project} archived={true}/>;
            })}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, { getProjects })(ArchivedProjectList);
