import * as Yup from 'yup';

export const taskFormSchema = Yup.object({
  title: Yup.string()
    .min(3, 'Must be 3 characters at minimum')
    .max(140, 'Must be 140 characters or less')
    .required('Required'),
  description: Yup.string()
    .min(3, 'Must be 3 characters at minimum')
    .max(1000, 'Must be 1000 characters or less'),   
  complete: Yup.boolean(),
  assignedTo: Yup.string(),
  department: Yup.string(),
  project: Yup.string(),
  approved: Yup.boolean(),
  delay: Yup.number(),
  dueDate: Yup.date(),
  audit: Yup.array(),
});
