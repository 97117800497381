import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';

import requireAuth from '../../hoc/requireAuth';
import {
  getDepartment,
  getUserForDepartment,
  deleteUserForDepartment,
} from '../../store/actions/departmentActions';
import './styles.css';

import { departmentUsersFormSchema } from './validation';

const ProjectDepartmentsUsersEdit = ({
  getDepartment,
  department: { department, isLoading, error },
  history,
  match,
  getUserForDepartment,
  deleteUserForDepartment,
}) => {
  const matchDepartmentId = match.params.departmentId;
  const [addUserError, setAddUserError] = useState(null);
  const [isSave, setIsSave] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: departmentUsersFormSchema,
    onSubmit: (values, { resetForm }) => {
      // editDepartment(values.id, { name: values.name, color: values.color, viewOnly: values.viewOnly, external: values.external, project: values.project.id, users: values.users });
      // Check if the user already exists in the department
      const userExists = department.users.some((user) => user.email === formik.values.email);
      if (userExists) {
        setAddUserError('This user is already in the department.');
        return;
      } else {
        getUserForDepartment({ email: formik.values.email, departmentID: department.id });
        setAddUserError(null);
        formik.setFieldTouched('email', false, false);
        formik.setFieldValue('email', '');
        setIsSave(true);
      }

      // resetForm();
    },
  });

  const handleRemoveUser = (email, departmentID) => {
    // Check if the user already exists in the department
    // const userExists = department.users.some(user => user.email === email);
    // if (!userExists) {
    //   setAddUserError('This user is already in the department.');
    //   return;
    // } else {
    setAddUserError(null);
    // formik.setFieldTouched('email', false, false);
    deleteUserForDepartment({ email, departmentID });
    // formik.setFieldValue('email', '');
    setIsSave(true);
    // }
  };

  useEffect(() => {
    getDepartment(matchDepartmentId, true, history);
  }, [getDepartment, matchDepartmentId, history]);

  useEffect(() => {
    getDepartment(matchDepartmentId, true, history);
    setIsSave(false);
  }, [isSave, setIsSave, getDepartment, matchDepartmentId, history]);

  return (
    <Layout>
      <div className="department-users-edit-page">
        {error && <div className="error-center">{error}</div>}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h1>
              {department.project && department.project.title} / {department.name} Department /{' '}
              <i>Members</i>
            </h1>
            <div className="department-users-list-container">
              <div className="department-users-list">
                {department.users
                  ? department.users.map((user, index) => {
                      return (
                        <div key={index} className="department-user">
                          <div className="department-users-list-item">
                            <div className="user-name">{user.name}</div>
                            <div className="user-info-container">
                              <div className="user-info user-username">@{user.username}</div>
                              <div className="user-info user-email">{user.email}</div>
                            </div>
                          </div>
                          <div className="department-users-actions">
                            <button
                              onClick={() => handleRemoveUser(user.email, department.id)}
                              type="button"
                              className="btn department-users-btn"
                            >
                              <FontAwesomeIcon icon={faXmark} /> Remove
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="department-users-form">
                <h2>Add a member</h2>
                <form onSubmit={formik.handleSubmit}>
                  <>
                    <input
                      name="email"
                      placeholder="User email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      disabled={department.isLoading}
                    />
                    {(formik.touched.email && formik.errors.email) || addUserError ? (
                      <p className="error">{formik.errors.email || addUserError}</p>
                    ) : null}
                    {department.error ? <p className="error">{department.error}</p> : null}
                    <button type="submit" className="btn save-btn" disabled={department.isLoading}>
                      Add Member
                    </button>
                  </>
                </form>
              </div>
            </div>
            <div className="form-actions">
              <Link to={`/project/department/edit/${department.id}`} className="btn return-btn">
                Back to Department
              </Link>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  department: state.department,
});

// export default compose(requireAuth, withRouter, connect(mapStateToProps))(ProjectDepartmentsEdit);
export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getDepartment, getUserForDepartment, deleteUserForDepartment }),
)(ProjectDepartmentsUsersEdit);
