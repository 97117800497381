import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faStar } from '@fortawesome/free-solid-svg-icons';

const TeamPulseCheck = ({ rows, isGlobalEdit, deleteRow, editRow, handleRateRow }) => {
  
  const [displayedRows, setDisplayedRows] = useState([]);
  
  useEffect(() => {    
    setDisplayedRows(rows.map((row, originalIndex) => ({ ...row, originalIndex })));        
  }, [rows, isGlobalEdit])
//   console.log("data", data);
//   console.log("master", master);
  
  return (
    <>
      <div className="huddle-board-content__team-pulse-check team-pulse-check">
            {/* <h2 className="huddle-board-content__title title text-center">{title}</h2> */}

            <div className="table-wrapper">
                <table className="form-team-pulse-check__table table">
                    <thead>
                        <tr>
                            <th className="tpc_actions">Actions</th>
                            <th className="tpc_question">Question</th>
                            <th className="tpc_rating">Rating</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {displayedRows.map((row, idx) => {
                            return (
                                <tr key={idx} className={`${row?.rating === 5 ? 'rating-5' : row?.rating === 4 ? 'rating-4' : row?.rating === 3 ? 'rating-3' : row?.rating === 2 ? 'rating-2' : row?.rating === 1 ? 'rating-1' : ''}`}>
                                    <td className="tpc_actions">
                                        <span className="actions">
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                className="edit-btn"
                                                onClick={() => editRow(row.originalIndex)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className=" delete-btn"
                                                onClick={() => deleteRow(row.originalIndex)}
                                            />
                                        </span>
                                    </td>
                                    <td className="tpc_question">{row?.question}</td>                                    
                                    <td className="tpc_rating">
                                      <FontAwesomeIcon
                                          icon={faStar}
                                          className={`rating-btn ${row?.rating >= 1 ? 'rating-1' : ''}`}
                                          onClick={() => handleRateRow(row.originalIndex, 1)}
                                      />
                                      <FontAwesomeIcon
                                          icon={faStar}
                                          className={`rating-btn ${row?.rating >= 2 ? 'rating-2' : ''}`}
                                          onClick={() => handleRateRow(row.originalIndex, 2)}
                                      />
                                      <FontAwesomeIcon
                                          icon={faStar}
                                          className={`rating-btn ${row?.rating >= 3 ? 'rating-3' : ''}`}
                                          onClick={() => handleRateRow(row.originalIndex, 3)}
                                      />
                                      <FontAwesomeIcon
                                          icon={faStar}
                                          className={`rating-btn ${row?.rating >= 4 ? 'rating-4' : ''}`}
                                          onClick={() => handleRateRow(row.originalIndex, 4)}
                                      />
                                      <FontAwesomeIcon
                                          icon={faStar}
                                          className={`rating-btn ${row?.rating === 5 ? 'rating-5' : ''}`}
                                          onClick={() => handleRateRow(row.originalIndex, 5)}
                                      />                                      
                                    </td>                        
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="global-edit-indicator">
                {isGlobalEdit ? 
                    (                    
                        <div className="global-edit-indicator__true">* Editing <b>Global Template</b> of Team Pulse Check</div>                    
                    ) : (
                        <div className="global-edit-indicator__false">* Editing <b>Selected</b> Week's Team Pulse Check</div>
                    )
                }
            </div>
        </div>
    </>
  );

};

export default TeamPulseCheck;
