// import React, { useEffect } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment-timezone';

import { addTask } from '../../store/actions/taskActions';
import { taskFormSchema } from './validation';

import './styles.css';

const TaskForm = ({ 
  auth, 
  addTask, 
  projectID, 
  department, 
  task: { 
    tasks, 
    // isLoading, 
    // error 
  }, 
  // isTaskFormVisible, 
  toggleTaskFormVisibility}) => {

  let isSubmiting = tasks.some((t) => t.id === 0);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      complete: false,
      assignedTo: '',
      department: department.id,
      project: projectID,
      approved: false,
      delay: 0,
      dueDate: moment().format('YYYY-MM-DD'),
      audit: [],
    },
    validationSchema: taskFormSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      
      addTask({ 
        title: values.title, 
        description: values.description, 
        complete: values.complete, 
        assignedTo: values.assignedTo === '' ? null : values.assignedTo, 
        department: values.department, 
        project: values.project, 
        approved: values.approved, 
        delay: values.delay, 
        dueDate: moment(values.dueDate).toISOString(),
        audit: [
          {
            user: auth.me.id,
            action: 'created',
            date: moment().toISOString(), //.split('T')[0],
            old: {},
            new: {},
          }
        ]
      });      
      resetForm();
      setSubmitting(false);      
      
      toggleTaskFormVisibility(department.id);
    },
  });

  return (
    <>
      <div className="task-form-background-overlay" onClick={() => toggleTaskFormVisibility(department.id)}></div>
      <div className='new-task-form-container'>
        <div className="task-form" style={{backgroundColor: department.color}}>
          <h2 className='task-form-title'>New Task</h2>
          <div className='task-form-department'>{department.name}</div>
          <form onSubmit={formik.handleSubmit}>        

            <h4 className='label'>Task Title</h4>
            {/* Task Title */}
            <input
              name="title"          
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              disabled={isSubmiting}
            />            
            {(formik.touched.title && formik.errors.title) ? (
              <p className="error">{formik.errors.title}</p>
            ) : null}

            <h4 className='label'>Task Description</h4>
            {/* Task Description */}
            <textarea
              name="description"
              rows="3"
              cols="30"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              disabled={isSubmiting}
            />
            {formik.touched.description && formik.errors.description ? (
              <p className="error">{formik.errors.description}</p>
            ) : null}

            <h4 className='label'>Assigned To</h4>
            {/* Assigned To */}
            <select
              name="assignedTo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.assignedTo}
              disabled={isSubmiting}
              className='task-form-select'
            >
              <option value="">None</option>
              {department.users.map((user) => (
                <option key={user.id} value={user.id}>{user.name}</option>
              ))}
            </select>
            {formik.touched.assignedTo && formik.errors.assignedTo ? (
              <p className="error">{formik.errors.assignedTo}</p>
            ) : null}

            <h4 className='label'>Due Date</h4>
            {/* Due Date */}
            <input
              name="dueDate"
              type="date"
              min={moment().add(-1, 'days').format('YYYY-MM-DD')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dueDate}
              disabled={isSubmiting}
            />
            {formik.touched.dueDate && formik.errors.dueDate ? (
              <p className="error">{formik.errors.dueDate}</p>
            ) : null}


            
            <div className='task-form-buttons'>
              <button type="submit" className="btn task-form-buttons-create" value="Create" disabled={isSubmiting} >Create</button>
              <button type="button" className="btn task-form-buttons-cancel" onClick={() => toggleTaskFormVisibility(department.id)}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  task: state.task,
  auth: state.auth,
});

export default connect(mapStateToProps, { addTask })(TaskForm);
