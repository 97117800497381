import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { useFormik } from 'formik';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';
import requireAuth from '../../hoc/requireAuth';

import '../../components/DepartmentList/styles.css';

import {
  getProject,
  deleteProject,
  editProject,
  clearProjectError,
} from '../../store/actions/projectActions';
import { getDepartments } from '../../store/actions/departmentActions';
import { projectFormSchema } from './validation';

import './styles.css';

const ProjectEdit = ({
  getProject,
  getDepartments,
  auth,
  thisProject: { project, isLoading, error },
  department: { departments, isLoading: departmentsIsLoading, error: departmentsError },
  editProject,
  clearProjectError,
  history,
  match,
}) => {
  const matchProjectId = match.params.projectId;
  const [isSave, setIsSave] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: project.title || '',
      id: project.id || '',
      projectPlan: project.projectPlan || '',
      risks: project.risks || '',
      opportunities: project.opportunities || '',
      resources: project.resources || '',
      fontStyle: project.fontStyle || 'Reenie-Beanie',
      fontWeight: project.fontWeight || 'bold',
      fontSizeLarge: project.fontSizeLarge || '37px',
      fontSizeSmall: project.fontSizeSmall || '25px',
      podMasters: project.podMasters || [],
    },
    validationSchema: projectFormSchema,
    onSubmit: (values, { resetForm }) => {
      const podMasterIDs = values.podMasters.map((podMaster) => podMaster.id);
      editProject(values.id, {
        title: values.title,
        projectPlan: values.projectPlan,
        risks: values.risks,
        opportunities: values.opportunities,
        resources: values.resources,
        fontStyle: values.fontStyle,
        fontWeight: values.fontWeight,
        fontSizeLarge: values.fontSizeLarge,
        fontSizeSmall: values.fontSizeSmall,
        podMasters: podMasterIDs,
      });
      clearProjectError(project.id);
      setIsSave(true);
      // getProject(matchProjectId, true, history);
      // resetForm();
    },
  });

  useEffect(() => {
    getProject(matchProjectId, true, history);
    getDepartments(matchProjectId);
    setIsSave(false);
  }, [isSave, getDepartments, getProject, history, matchProjectId]);

  return (
    <Layout>
      <div className="project-edit-page">
        {error && <div className="error-center">{error}</div>}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="form-container">
            <h1>{project.title}</h1>
            <span className="time text-light">{`Created ${moment(
              project.createdAt,
            ).fromNow()}`}</span>
            {project.user ? (
              <>
                <span className="fullname text-light">{` by: ${project.user.name}`}</span>
              </>
            ) : null}
            <br />
            {!moment(project.createdAt).isSame(project.updatedAt, 'minute') && (
              <span className="time text-light">{`Edited: ${moment(
                project.updatedAt,
              ).fromNow()}`}</span>
            )}

            <form onSubmit={formik.handleSubmit}>
              <input type="hidden" name="id" />
              <h3 className="label">Pod Title</h3>
              {/* Project Title */}
              <input
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                disabled={project.isLoading}
              />
              {formik.touched.title && formik.errors.title ? (
                <p className="error">{formik.errors.title}</p>
              ) : null}

              <h4 className="label">Huddle Boards Settings</h4>
              <h5 className='label'>Project Plan Link</h5>
              {/* Project Plan */}
              <input
                  name="projectPlan"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.projectPlan}
                  disabled={project.isLoading}
                />
                {formik.touched.projectPlan && formik.errors.projectPlan ? (
                  <p className="error">{formik.errors.projectPlan}</p>
                ) : null}

              <h5 className='label'>Risks Link</h5>
              {/* Risks */}
              <input
                  name="risks"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.risks}
                  disabled={project.isLoading}
                />
                {formik.touched.risks && formik.errors.risks ? (
                  <p className="error">{formik.errors.risks}</p>
                ) : null}

              <h5 className='label'>Opportunities Link</h5>
              {/* Opportunities */}
              <input
                  name="opportunities"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.opportunities}
                  disabled={project.isLoading}
                />
                {formik.touched.opportunities && formik.errors.opportunities ? (
                  <p className="error">{formik.errors.opportunities}</p>
                ) : null}

              {/* <h3 className='label'>Resources Link</h3> */}
              {/* Resources */}
              {/* <input
                  name="resources"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.resources}
                  disabled={project.isLoading}
                />                                    
                {formik.touched.resources && formik.errors.resources ? (
                  <p className="error">{formik.errors.resources}</p>
                ) : null}*/}

              <h4 className="label">Task Settings</h4>

              <h5 className="label">Font Style</h5>

              <select
                name="fontStyle"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fontStyle}
                style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight }}
                disabled={project.isLoading}
                className="project-edit-form-select"
              >
                <option
                  value="Brush Script MT"
                  style={{ fontFamily: 'Brush Script MT', fontWeight: formik.values.fontWeight }}
                >
                  Brush Script MT
                </option>
                <option
                  value="Courier New"
                  style={{ fontFamily: 'Courier New', fontWeight: formik.values.fontWeight }}
                >
                  Courier New
                </option>
                <option
                  value="Garamond"
                  style={{ fontFamily: 'Garamond', fontWeight: formik.values.fontWeight }}
                >
                  Garamond
                </option>
                <option
                  value="'Klyakson', sans-serif"
                  style={{ fontFamily: "'Klyakson', sans-serif", fontWeight: formik.values.fontWeight }}
                >
                  Klyakson
                </option>
                <option value="Lato" style={{ fontFamily: 'Lato', fontWeight: formik.values.fontWeight }}>
                  Lato
                </option>
                <option
                  value="Reenie-Beanie"
                  style={{ fontFamily: 'Reenie-Beanie', fontWeight: formik.values.fontWeight }}
                >
                  Reenie Beanie
                </option>
                <option
                  value="'Roboto', sans-serif"
                  style={{ fontFamily: "'Roboto', sans-serif", fontWeight: formik.values.fontWeight }}
                >
                  Roboto
                </option>
              </select>

              <h5 className="label">Font Weight</h5>
              <select
                name="fontWeight"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fontWeight}
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight            
                }}
                disabled={project.isLoading}
                className="project-form-select"
              >
                <option 
                  value="bold"
                  style={{ fontFamily: formik.values.fontStyle, fontWeight: 'bold' }}
                >
                  Bold
                </option>
                <option
                  value="normal"
                  style={{ fontFamily: formik.values.fontStyle, fontWeight: 'normal' }}
                >
                  Regular
                </option>
              </select>

              <h5 className="label">Font Size - Large Screen</h5>

              <select
                name="fontSizeLarge"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fontSizeLarge}
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: formik.values.fontSizeLarge,
                }}
                disabled={project.isLoading}
                className="project-edit-form-select"
              >
                <option
                  value="25px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '25px',
                  }}
                >
                  XSmall
                </option>
                <option
                  value="31px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '31px',
                  }}
                >
                  Small
                </option>
                <option
                  value="37px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '37px',
                  }}
                >
                  Medium
                </option>
                <option
                  value="43px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '43px',
                  }}
                >
                  Large
                </option>
                <option
                  value="49px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '49px',
                  }}
                >
                  XLarge
                </option>
              </select>

              <h5 className="label">Font Size - Mobile Screen</h5>

              <select
                name="fontSizeSmall"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fontSizeSmall}
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: formik.values.fontSizeSmall,
                }}
                disabled={project.isLoading}
                className="project-edit-form-select"
              >
                <option
                  value="17px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '17px',
                  }}
                >
                  XSmall
                </option>
                <option
                  value="21px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '21px',
                  }}
                >
                  Small
                </option>
                <option
                  value="25px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '25px',
                  }}
                >
                  Medium
                </option>
                <option
                  value="29px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '29px',
                  }}
                >
                  Large
                </option>
                <option
                  value="33px"
                  style={{
                    fontFamily: formik.values.fontStyle,
                    fontWeight: formik.values.fontWeight,
                    fontSize: '33px',
                  }}
                >
                  XLarge
                </option>
              </select>

              <div className="department-list">
                <h3 className="label">
                  Departments (
                  <Link to={`/project/departments/edit/${project.id}`} className="header-link">
                    Edit
                  </Link>
                  )
                </h3>

                {departmentsError && <div className="error-center">{departmentsError}</div>}
                <Link
                  to={`/project/departments/edit/${project.id}`}
                  className="department-list-link"
                >
                  <div className="department-list-container">
                    {departmentsIsLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {departments.length === 0 ? (
                          <p>No departments created</p>
                        ) : (
                          <>
                            {departments.map((department, index) => {
                              // return <Department key={index} department={department} />;
                              return (
                                <div className="department-list-item" key={index}>
                                  <div className="department-row">
                                    <div
                                      className="department-color-box"
                                      style={{ backgroundColor: department.color }}
                                    ></div>
                                    <div className="department-name">{department.name}</div>
                                  </div>
                                  <div className="department-tags">
                                    <div className="user-count">
                                      {department.users.length} members &nbsp;
                                    </div>
                                    {department.viewOnly ? (
                                      <div className="view-only">View Only &nbsp;</div>
                                    ) : null}
                                    {department.external ? (
                                      <div className="external">External &nbsp;</div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Link>
              </div>

              <div className="department-users-list">
                <h3 className="label">
                  Pod Masters (
                  <Link to={`/project/podmasters/edit/${project.id}`} className="header-link">
                    Edit
                  </Link>
                  )
                </h3>
                <Link to={`/project/podmasters/edit/${project.id}`} className="user-list-link">
                  <div className="user-list-container">
                    {formik.values.podMasters.length === 0 ? (
                      <p>No Pod Masters selected</p>
                    ) : (
                      <>
                        {formik.values.podMasters.map((podMaster, index) => {
                          return (
                            <div key={index} className="user-list-item">
                              <div>
                                {/* <div className="label">Name: </div> */}
                                <div className="user-name">{podMaster.name}</div>
                              </div>
                              <div className="user-info-container">
                                <div>
                                  {/* <div className="label">Username: </div> */}
                                  <div className="user-info user-username">
                                    @{podMaster.username}
                                  </div>
                                </div>
                                <div>
                                  {/* <div className="label">Email: </div> */}
                                  <div className="user-info user-email">{podMaster.email}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Link>
              </div>

              <div className="form-actions">
                {project.error ? <p className="error">{project.error}</p> : null}
                <button type="submit" className="btn save-btn" disabled={project.isLoading}>
                  Save
                </button>
                <Link to={'/'} className="btn return-btn">
                  Back to Home
                </Link>
              </div>
            </form>
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  department: state.department,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, {
    getProject,
    deleteProject,
    editProject,
    clearProjectError,
    getDepartments,
  }),
)(ProjectEdit);
