import React from 'react';

import '../styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
const GoldenMoments = ({ rows, title, deleteRow, editRow }) => {
  const departments = useSelector((state) => state.department.departments);
  const orderedMoments = rows
    .map((row, originalIndex) => ({ ...row, originalIndex }))
    .slice()
    .sort((a, b) => {
      return moment(b.date).diff(moment(a.date));
    });

  return (
    <div className="huddle-board-content__golden-moments golden-moments">
      <h2 className="huddle-board-content__title title text-center">{title}</h2>

      <div className="table-wrapper">
        <table className="form-golden-moments__table table">
          <thead>
            <tr>
              <th className="gm_name">Name(s)</th>
              <th className="gm_description">Description</th>
              <th className="gm_department">Department</th>
              <th className="gm_when">When</th>
              <th className="gm_actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orderedMoments.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td className="gm_name">{row?.name}</td>
                  <td className="gm_description">{row?.description}</td>
                  <td className="gm_department">
                    <span className="label">
                      {departments.find((el) => el.id === row?.department)?.name}
                    </span>
                  </td>
                  <td className="gm_when">{row?.date}</td>

                  <td className="gm_actions">
                    <span className="actions">
                      <FontAwesomeIcon
                        icon={faPen}
                        className="edit-btn"
                        onClick={() => editRow(row.originalIndex)}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        className=" delete-btn"
                        onClick={() => {
                          deleteRow(row.originalIndex);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GoldenMoments;
