import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';

import requireAuth from '../../hoc/requireAuth';
import {
  getProject,
  getPodmasterForProject,
  deletePodmasterForProject,
} from '../../store/actions/projectActions';
import './styles.css';

import { podMastersFormSchema } from './validation';

const ProjectPodMastersEdit = ({
  getProject,
  project: { project, isLoading, error },
  history,
  match,
  getPodmasterForProject,
  deletePodmasterForProject,
}) => {
  const matchProjectId = match.params.projectId;
  const [addUserError, setAddUserError] = useState(null);
  const [isSave, setIsSave] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: podMastersFormSchema,
    onSubmit: (values, { resetForm }) => {
      const userExists = project.podMasters.some((user) => user.email === formik.values.email);
      if (userExists) {
        setAddUserError('This Pod Master is already in the project.');
        return;
      } else {
        getPodmasterForProject({ email: formik.values.email, projectID: project.id });
        setAddUserError(null);
        formik.setFieldTouched('email', false, false);
        formik.setFieldValue('email', '');
        setIsSave(true);
      }

      // resetForm();
    },
  });

  const handleRemoveUser = (email, projectID) => {
    setAddUserError(null);

    deletePodmasterForProject({ email, projectID });

    setIsSave(true);
  };

  useEffect(() => {
    getProject(matchProjectId, true, history);
  }, [getProject, history, matchProjectId]);

  useEffect(() => {
    // getProject(matchProjectId, true, history);
    setIsSave(false);
  }, [isSave]);

  return (
    <Layout>
      <div className="department-users-edit-page">
        {error && <div className="error-center">{error}</div>}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h1>
              {project.title} / <i>Pod Masters</i>
            </h1>
            <div className="department-users-list-container">
              <div className="department-users-list">
                {project.podMasters
                  ? project.podMasters.map((user, index) => {
                      return (
                        <div key={index} className="department-user">
                          <div className="department-users-list-item">
                            <div className="user-name">{user.name}</div>
                            <div className="user-info-container">
                              <div className="user-info user-username">@{user.username}</div>
                              <div className="user-info user-email">{user.email}</div>
                            </div>
                          </div>
                          <div className="department-users-actions">
                            <button
                              onClick={() => handleRemoveUser(user.email, project.id)}
                              type="button"
                              className="btn department-users-btn"
                            >
                              <FontAwesomeIcon icon={faXmark} /> Remove
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="department-users-form">
                <h2>Add a member</h2>
                <form onSubmit={formik.handleSubmit}>
                  <>
                    <input
                      name="email"
                      placeholder="User email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      disabled={project.isLoading}
                    />
                    {(formik.touched.email && formik.errors.email) || addUserError ? (
                      <p className="error">{formik.errors.email || addUserError}</p>
                    ) : null}
                    {project.error ? <p className="error">{project.error}</p> : null}
                    <button type="submit" className="btn save-btn" disabled={project.isLoading}>
                      Add Member
                    </button>
                  </>
                </form>
              </div>
            </div>
            <div className="form-actions">
              <Link to={`/project/edit/${project.id}`} className="btn return-btn">
                Back to Project
              </Link>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getProject, getPodmasterForProject, deletePodmasterForProject }),
)(ProjectPodMastersEdit);
