import React, { useMemo, useState } from 'react';
import MetricLetters from '../Metrica/MetricLetters';
import LetterP from './LetterP';
import LetterS from './LetterS';
import LetterC from './LetterC';
import LetterD from './LetterD';
import LetterQ from './LetterQ';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faXmark, faCheck, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

// Function to format date to DD-MM-YY
const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
};
const componentMapping = (title, propsToPass, selectedDate, setSelectedDate) => {
  switch (title) {
    case 'People':
      return <LetterP huddleBoard={propsToPass} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />;
    case 'Safety':
      return <LetterS huddleBoard={propsToPass} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />;
    case 'Cost':
      return <LetterC huddleBoard={propsToPass} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />;
    case 'Quality':
      return <LetterQ huddleBoard={propsToPass} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />;
    case 'Delivery':
      return <LetterD huddleBoard={propsToPass} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />;
    default:
      return <p>No specified Letter found</p>;
  }
};

const LetterLayout = ({ updateHuddleBoard, title, huddleBoard, labels }) => {
  const [formVisibility, setFormVisibility] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const { firstDayFormatted, lastDayFormatted, computedLabels } = useMemo(() => {
    // const currentDate = new Date();
    const currentDate = selectedDate;
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayFormatted = formatDate(firstDayOfMonth);
    const lastDayFormatted = formatDate(lastDayOfMonth);
    const computedLabels = labels.length === 2 ? [...labels, 'Days Left'] : [...labels];
    return { firstDayFormatted, lastDayFormatted, computedLabels };
  }, [labels, selectedDate]);

  const validationSchema = Yup.object({
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      description: huddleBoard?.description,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      updateHuddleBoard(huddleBoard.id, 'previous', {
        ...huddleBoard,
        description: values.description,
      });
      handleFormVisibilityToggle();
      // resetForm();
    },
  });
  
  const handleFormVisibilityToggle = () => {
    setFormVisibility(!formVisibility);
  };
  return (
    <>
      <h2 className="huddle-board-content__title title">{title}</h2>
      {!formVisibility ? (
        <div className="huddle-board-content__description-row">
          <p className="huddle-board-content__description description">
            {huddleBoard?.description}
          </p>
          <FontAwesomeIcon
            icon={faPen}
            className="huddle-board-content__description title-edit-icon"
            onClick={handleFormVisibilityToggle}
          />
        </div>
      ) : (
        <div className="huddle-board-content-edit">
          <form
            className={`edit-form ${formVisibility ? 'form-active' : ''}`}
            onSubmit={formik.handleSubmit}
          >
            <label htmlFor="description" className="sr-only">
              Edit Description
            </label>
            <input
              type="text"
              id="description"
              name="description"
              placeholder="Enter Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <button type="submit" className="edit-submit-button">
              <FontAwesomeIcon
                icon={faCheck}
                className="huddle-board-content__description title-edit-save-icon"
              />
            </button>
            <button className="edit-submit-button">
              <FontAwesomeIcon
                icon={faXmark}
                className="huddle-board-content__description title-edit-cancel-icon"
                onClick={handleFormVisibilityToggle}
              />
            </button>
          </form>
        </div>
      )}

      <span className="huddle-board-content__date-row date-row">
        {/* 📅 {firstDayFormatted} - {lastDayFormatted} */}
        <div className="month-select-button" onClick={() => setSelectedDate(new Date(moment(selectedDate).add(-1, 'months').toISOString()))}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="month-select-current">📅 {moment(selectedDate).format('MMMM YYYY')}</div>
        <div className="month-select-button" onClick={() => setSelectedDate(new Date(moment(selectedDate).add(1, 'months').toISOString()))}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </span>
      <div className="huddle-board-content-row ">
        <div className="huddle-board-content__container container">
          {componentMapping(title, huddleBoard, selectedDate, setSelectedDate)}
        </div>
        <MetricLetters
          updateHuddleBoard={updateHuddleBoard}
          huddleBoard={huddleBoard}
          title={title}
          labels={computedLabels}
          selectedDate={selectedDate}
        />
      </div>
    </>
  );
};

export default connect(null, { updateHuddleBoard })(LetterLayout);
