import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { connect } from 'react-redux';

const HuddleBoardForm = ({ projectId, title, createHuddleBoard, simple }) => {
  // Validation Schema
  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    // description: Yup.string().required('Description is required'),
    description: Yup.string().optional(),
    data: Yup.string(),
  });

  const initialValues = {
    project: projectId,
    title: title,
    description: simple ? title : '',
    order: 1,
    data: [],
  };
  
  const handleSubmit = (values, { resetForm }) => {
    createHuddleBoard(values);
    // resetForm(); // Reset form after submission
  };

  return (
    <div className="huddle-board-init">
      {simple ?
        <h2>Ready to track {title}?</h2>
      :
        <h2>To get started, enter the description for the {title} KPI:</h2>
      }
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="huddle-board-init-form">
            {/* <div>
              <label htmlFor="title">Title:</label>
              <Field type="text" name="title" />
              <ErrorMessage name="title" component="div" className="error" />
            </div> */}

            {!simple && (
              <div>
                {/* <label htmlFor="description">Description:</label> */}
                <Field type="text" name="description" placeholder="Enter Description" />
                <ErrorMessage name="description" component="div" className="error" />
              </div>
            )}
            {/* <div>
              <label htmlFor="order">Order:</label>
              <Field type="text" name="order" />
              <ErrorMessage name="order" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="description">JSON Format Data:</label>
              <Field type="text" name="data" />
              <ErrorMessage name="data" component="div" className="error" />
            </div> */}

            <button type="submit" disabled={isSubmitting}>
              {simple ? 'Start Tracking' : 'Initialize KPI'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect(null, { createHuddleBoard })(HuddleBoardForm);
