import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_DEPARTMENTS_LOADING,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAIL,
  GET_DEPARTMENT_LOADING,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAIL,
  ADD_DEPARTMENT_LOADING,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAIL,
  DELETE_DEPARTMENT_LOADING,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAIL,
  EDIT_DEPARTMENT_LOADING,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_FAIL,
  CLEAR_DEPARTMENT_ERROR,
  GET_DEPARTMENTS_PROFILE_LOADING,
  // GET_DEPARTMENTS_PROFILE_SUCCESS,
  GET_DEPARTMENTS_PROFILE_FAIL,
} from '../types';

export const getDepartments = (projectId) => async (dispatch, getState) => {
  dispatch({
    type: GET_DEPARTMENTS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/project/${projectId}`, options);

    dispatch({
      type: GET_DEPARTMENTS_SUCCESS,
      payload: { departments: response.data.departments },
    });
  } catch (err) {
    dispatch({
      type: GET_DEPARTMENTS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getDepartment = (departmentId, history) => async (dispatch, getState) => {
  dispatch({
    type: GET_DEPARTMENT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/${departmentId}`, options);

    dispatch({
      type: GET_DEPARTMENT_SUCCESS,
      payload: { department: response.data.department },
    });
  } catch (err) {
    if (err?.response.status === 404) {
      history.push('/notfound');
    }
    dispatch({
      type: GET_DEPARTMENT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const addDepartment = (formData) => async (dispatch, getState) => {
  dispatch({
    type: ADD_DEPARTMENT_LOADING,
    payload: { department: { ...getState().auth.department } },
    // payload: { me: { ...getState().auth.me } },
  });
  // console.log("Department formData:", formData);
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments`, formData, options);

    // console.log("Department response:", response);

    dispatch({
      type: ADD_DEPARTMENT_SUCCESS,
      payload: { department: response.data.department },
    });
  } catch (err) {
    // console.log("Department error:", err);
    dispatch({
      type: ADD_DEPARTMENT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteDepartment = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_DEPARTMENT_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/${id}`, options);

    dispatch({
      type: DELETE_DEPARTMENT_SUCCESS,
      payload: { department: response.data.department },
    });
  } catch (err) {
    dispatch({
      type: DELETE_DEPARTMENT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editDepartment = (id, formData) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_DEPARTMENT_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/${id}`, formData, options);

    dispatch({
      type: EDIT_DEPARTMENT_SUCCESS,
      payload: { department: response.data.department },
    });
  } catch (err) {
    dispatch({
      type: EDIT_DEPARTMENT_FAIL,
      payload: { error: err?.response?.data.message || err.message, id },
    });
  }
};

export const getUserForDepartment = (formData) => async (dispatch, getState) => {
  dispatch({
    type: GET_DEPARTMENTS_PROFILE_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("Searching email:", formData.email);
    const responseUser = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/users/email/${formData.email}`, options);
    const responseDepartment = await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/adduser/${formData.departmentID}`, responseUser.data.user, options);
    // const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/project/${responseDepartment.data.department.project.id}`, options);

    dispatch({
      type: GET_DEPARTMENT_SUCCESS,
      // payload: { departmentProfile: response.data.user },
      payload: { department: responseDepartment.data.department },
    });
    // dispatch({
    //   type: GET_DEPARTMENTS_SUCCESS,
    //   // payload: { departmentProfile: response.data.user },
    //   payload: { departments: response.data.departments },
    // });
  } catch (err) {    
    dispatch({
      type: GET_DEPARTMENTS_PROFILE_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteUserForDepartment = (formData) => async (dispatch, getState) => {
  dispatch({
    type: GET_DEPARTMENTS_PROFILE_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("Searching email:", formData.email);
    const responseUser = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/users/email/${formData.email}`, options);
    const responseDepartment = await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/removeuser/${formData.departmentID}`, responseUser.data.user, options);
    // const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/project/${responseDepartment.data.department.project.id}`, options);

    dispatch({
      type: GET_DEPARTMENT_SUCCESS,
      // payload: { departmentProfile: response.data.user },
      payload: { department: responseDepartment.data.department },
    });
    // dispatch({
    //   type: GET_DEPARTMENTS_SUCCESS,
    //   // payload: { departmentProfile: response.data.user },
    //   payload: { departments: response.data.departments },
    // });
  } catch (err) {    
    dispatch({
      type: GET_DEPARTMENTS_PROFILE_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const clearDepartmentError = (id) => ({
  type: CLEAR_DEPARTMENT_ERROR,
  payload: { id },
});
