import React, { useState, useEffect, useRef } from 'react';
// import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';
import requireAuth from '../../hoc/requireAuth';

import TaskForm from '../../components/TaskForm/TaskForm';
import Task from '../../components/Task/Task';
import DailyTaskCell from '../../components/DailyTaskCell/DailyTaskCell';
import HuddleBoards from '../../components/HuddleBoards/HuddleBoards';
import '../../components/DepartmentList/styles.css';

import {
  getProject,  
  clearProjectError,
} from '../../store/actions/projectActions';
import { getProjectTasks } from '../../store/actions/taskActions';
import { getDepartments } from '../../store/actions/departmentActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faGavel, faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faGear, faPeopleGroup, faUserTie, faCheckDouble } from '@fortawesome/free-solid-svg-icons';

import { fetchHuddleBoards, updateHuddleBoard } from '../../store/actions/huddleBoardAction';
import './styles.css';

// import socketIOClient from 'socket.io-client';

const deconstructTask = (task) => {
  return {
    title: task.title,
    description: task.description,
    complete: task.complete,
    assignedTo: task.assignedTo.hasOwnProperty('id') ? task.assignedTo.id : null,
    department: task.department.id,
    project: task.project.id,
    approved: task.approved,
    delay: task.delay,
    dueDate: task.dueDate,
  };
};

const deconstructValues = (values) => {
  return {
    title: values.title,
    description: values.description,
    complete: values.complete,
    assignedTo: values.assignedTo === '' ? null : values.assignedTo,
    department: values.department,
    project: values.project,
    approved: values.approved,
    delay: values.delay,
    dueDate: values.dueDate,
  };
};

const desconstructAudit = (audit) => {
  let cleanAudit = [];
  audit.forEach((a) => {
    cleanAudit.push({
      user: a.user.id,
      action: a.action,
      date: a.date,
      old: a.old,
      new: a.new,
    });
  });
  return cleanAudit;
};

const ProjectView = ({
  getProject,
  getProjectTasks,
  getDepartments,
  auth,
  fetchHuddleBoards,
  updateHuddleBoard,
  task: { tasks, isLoading: tasksIsLoading, error: tasksError },
  thisProject: { project, isLoading, error },
  department: { departments, isLoading: departmentsIsLoading, error: departmentsError },
  huddleBoard: {
    huddleBoard,
    huddleBoards,
    error: huddleBoardError,
    isLoading: huddleboardIsLoading,
  },  
  history,
  match,
}) => {
  // const socketio = socketIOClient(process.env.REACT_APP_SERVER_BASE_URL);

  const matchProjectId = match.params.projectId;

  const [isTaskFormVisible, setIsTaskFormVisible] = useState({});

  const [isHuddleBoardVisible, setIsHuddleBoardVisible] = useState(false);

  const [departmentFilter, setDepartmentFilter] = useState({});

  const [secondsToRefresh, setSecondsToRefresh] = useState(10);

  const [hideApproveDecline, setHideApproveDecline] = useState(false);
 

  // VERTICAL SCROLL
  const deptScrollRef = useRef(null);
  const [ isMouseDown, setIsMouseDown ] = useState(false);
  const [ startX, setStartX ] = useState(0);
  const [ scrollLeft, setScrollLeft ] = useState(0);
 
  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    // setStartX(e.x);
    setStartX(e.pageX - - deptScrollRef.current.offsetLeft);
    setScrollLeft(deptScrollRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - deptScrollRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    deptScrollRef.current.scrollLeft = scrollLeft - walk;
  };
  // END VERTICAL SCROLL

  useEffect(() => {
    if (secondsToRefresh > -1) {
      const timerId = setInterval(() => {
        setSecondsToRefresh((prevSeconds) => prevSeconds - 1); // decrement seconds
      }, 1000);

      // Clean up the interval on component unmount or when seconds reach 0
      return () => clearInterval(timerId);
    } else {
      getProjectTasks(matchProjectId);
      // getProject(matchProjectId, true, history);
      // getDepartments(matchProjectId);
      setSecondsToRefresh(10);
    }
  }, [secondsToRefresh]);

  const toggleHuddleBoardVisibility = () => {
    setIsHuddleBoardVisible(!isHuddleBoardVisible);
  };

  const toggleTaskFormVisibility = (departmentId) => {
    // console.log('isTaskFormVisible', isTaskFormVisible);
    setIsTaskFormVisible({
      // ...isTaskFormVisible,
      [departmentId]: !isTaskFormVisible[departmentId],
    });
  };

  const toggleDepartmentFilter = (departmentId) => {
    setDepartmentFilter({
      ...departmentFilter,
      [departmentId]: !departmentFilter[departmentId],
    });
  };

  const toggleHideApproveDecline = () => {
    setHideApproveDecline(!hideApproveDecline);
  };

  useEffect(() => {
    getProjectTasks(matchProjectId);
    getProject(matchProjectId, true, history);
    getDepartments(matchProjectId);
    fetchHuddleBoards(matchProjectId);
    // disable task form visibility for all departments
    // departments.forEach((department) => {
    //   setIsTaskFormVisible({
    //     ...isTaskFormVisible,
    //     [department.id]: false,
    //   });
    // });
    setSecondsToRefresh(10);
  }, []);

  // is current user SUPERADMIN, POD MASTER or PROJECT OWNER
  const isAdmin = () => {
    return (
      auth.me.role === 'SUPERADMIN' ||
      project.podMasters?.some((pm) => pm.id === auth.me.id) ||
      auth.me.id === project.user?.id
    );
  };

  // is current user a member of "external" departments
  const isExternal = () => {
    return departments.some((department) => department.external && department.users.some((user) => user.id === auth.me.id));    
  }

  if (auth.isAuthenticated) {
    return (
      <Layout>
        <div className={`project-view-page ${isHuddleBoardVisible ? 'huddle-boards-active' : ''}`}>
          {error && <div className="error-center">{error}</div>}
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="project-title-bar">
                <div className="project-title-bar-left-container">
                  <div className="project-title-bar-left">
                    <h1 className="project-view-page-title">{project.title}</h1>
                    <p className="project-view-page-refresh-in">
                      {secondsToRefresh > 0
                        ? `Next refresh in ${secondsToRefresh} seconds`
                        : 'Fetching tasks...'}
                    </p>
                  </div>
                  <div className="project-title-bar-right-filters-container">
                    {isAdmin() && (
                      <div className="project-title-bar-right-filter-container decision-mode-filter-responsive">
                        <div
                          className={`project-title-bar-right-filter-button ${
                            hideApproveDecline ? 'filter-active' : ''
                          }`}
                          onClick={toggleHideApproveDecline}
                        >                        
                          <FontAwesomeIcon icon={faGavel} />                        
                        </div>
                      </div>
                    )}
                    
                    {!isExternal() && (
                      <div className="project-title-bar-right-filter-container huddle-boards-filter-responsive">
                        <div
                          className={`project-title-bar-right-filter-button`}
                          onClick={toggleHuddleBoardVisibility}
                        >                        
                          {isHuddleBoardVisible ? (
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                          ) : (
                            <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
                          )}                        
                        </div>
                      </div>
                    )}
                    <div className="project-title-bar-right-filter-container completed-tasks-filter-responsive">
                      <Link to={`/project/completed/${project.id}`}>
                        <div className={`project-title-bar-right-filter-button`}>                        
                          <FontAwesomeIcon icon={faCheckDouble} />                          
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="project-title-bar-right">
                  {isAdmin() && (
                    <div className="project-title-bar-right-filter-container decision-mode-filter">
                      <div
                        className={`project-title-bar-right-filter-button ${
                          hideApproveDecline ? 'filter-active' : ''
                        }`}
                        onClick={toggleHideApproveDecline}
                      >
                        {hideApproveDecline ? (
                          'DECISION MODE'
                        ) : (
                          <>
                            DECISION MODE &nbsp; <FontAwesomeIcon icon={faXmark} />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div 
                    ref={deptScrollRef} 
                    id="department-scrollable-filter" 
                    className="project-title-bar-right-filter-container mobile-scrollable-filters"
                    // onWheel={(e) => console.log('e', e)}
                    // onClick={() => deptScrollRef.current.scrollLeft += 20}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}
                  >
                    <div className="project-title-bar-right-filter-title">Departments:</div>
                    {departments.filter((department) => !department.viewOnly).map((department) => (
                      <div
                        key={department.id}
                        className={`project-title-bar-right-filter-button-department ${
                          departmentFilter[department.id] ? 'filter-active' : ''
                        }`}
                        style={{ backgroundColor: `${department.color}` }}
                        onClick={() => toggleDepartmentFilter(department.id)}
                      >
                        {department.name}
                        {departmentFilter[department.id] ? (
                          ''
                        ) : (
                          <>
                            &nbsp; <FontAwesomeIcon icon={faXmark} />
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                                    
                </div>
                <div id="project-nav-right-container" className="project-nav-right-container">
                  {/* {isAdmin() && (
                    <div className="project-title-bar-right-filter-container decision-mode-filter">
                      <div
                        className={`project-title-bar-right-filter-button ${
                          hideApproveDecline ? 'filter-active' : ''
                        }`}
                        onClick={toggleHideApproveDecline}
                      >
                        <FontAwesomeIcon icon={faGavel} />
                      </div>
                    </div>
                  )} */}
                  
                  {!isExternal() && (
                    <div
                      onClick={toggleHuddleBoardVisibility}
                      className={'project-nav-right-button'}
                    >                      
                      
                      {isHuddleBoardVisible ? (
                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                      ) : (
                        <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
                      )}
                    </div>
                  )}
                  <Link to={`/project/completed/${project.id}`}>
                    <div className={'project-nav-right-button'}>                          
                        <FontAwesomeIcon icon={faCheckDouble} />                          
                    </div>
                  </Link>
                  {isAdmin() && (
                    <>
                      <Link to={`/project/edit/${project.id}`}>
                        <div className={'project-nav-right-button'}>                          
                            <FontAwesomeIcon icon={faGear} />                          
                        </div>
                      </Link>
                      <Link to={`/project/podmasters/edit/${project.id}`}>
                        <div className={'project-nav-right-button'}>                          
                            <FontAwesomeIcon icon={faUserTie} />                          
                        </div>
                      </Link>
                      <Link to={`/project/departments/edit/${project.id}`}>
                        <div className={'project-nav-right-button'}>                          
                            <FontAwesomeIcon icon={faPeopleGroup} />                          
                        </div>
                      </Link>
                    </>
                  )}
                  
                </div>
              </div>
              <div className={`huddle-boards-container ${
                  isHuddleBoardVisible ? 'huddle-boards-container-active' : ''
                }`}
              >
                <HuddleBoards
                  huddleBoards={huddleBoards}
                  toggleHuddleBoardVisibility={toggleHuddleBoardVisibility}
                  projectId={matchProjectId}
                  isLoading={huddleboardIsLoading}
                  error={huddleBoardError}
                  updateHuddleBoard={updateHuddleBoard}
                  projectPlan={project.projectPlan}
                  projectRisks={project.risks}
                  projectOpportunities={project.opportunities}
                />
              </div>
              <div className="project-view-page-header">
                <div className="project-view-page-body-row-header-department-1">
                  <div>Departments</div>
                </div>
                <div className="project-view-page-body-row-header-1">
                  <div>Pacing</div>
                </div>
                <div className="project-view-page-body-row-header-1">
                  <div>Escalate</div>
                </div>
                <div className="project-view-page-body-row-header-1 due-column">
                  <div>Due</div>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(1, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(1, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(2, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(2, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(3, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(3, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(4, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(4, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(5, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(5, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(6, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(6, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(7, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(7, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(8, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(8, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(9, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(9, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(10, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(10, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(11, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(11, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(12, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(12, 'days').format('MMM DD')}
                  </span>
                </div>
                <div className="project-view-page-body-row-header-2">
                  {moment().add(13, 'days').format('dddd')}
                  <span className="project-view-page-body-row-header-2-date">
                    {moment().add(13, 'days').format('MMM DD')}
                  </span>
                </div>
              </div>
              <div className="project-view-page-body">

                {departments.filter((department) => !department.viewOnly).map((department) => (
                  <div
                    key={department.id}
                    className={`project-view-page-body-row ${
                      departmentFilter[department.id] ? 'hide-department' : ''
                    }`}
                  >
                    <div className="project-view-page-body-row-header-department-1">
                      <div style={{ margin: '10px' }}>
                        {isTaskFormVisible[department.id] && (
                          <TaskForm
                            department={department}
                            projectID={project.id}
                            isTaskFormVisible={isTaskFormVisible}
                            toggleTaskFormVisibility={toggleTaskFormVisibility}
                          />
                        )}
                      </div>
                      <div className="project-view-page-body-row-department-name">
                        <div>{department.name}</div>
                        {auth.me.role === 'SUPERADMIN' ||
                        department.users.some((user) => user.id === auth.me.id) ||
                        auth.me.id === project.user.id ||
                        project.podMasters.some((pm) => pm.id === auth.me.id) ? (
                          <div>
                            {isTaskFormVisible[department.id] ? null : (
                              <>
                                <button
                                  onClick={() => toggleTaskFormVisibility(department.id)}
                                  className="btn-add-task"
                                  style={{ backgroundColor: `${department.color}` }}
                                >
                                  + <span className="btn-add-task-tooltip">New Task</span>
                                </button>
                              </>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Pacing */}
                    <DailyTaskCell
                      dayNumber={0}
                      department={department}
                      tasks={tasks}
                      departments={departments}
                      project={project}
                      hideApproveDecline={hideApproveDecline}
                      auth={auth}
                      deconstructTask={deconstructTask}
                      deconstructValues={deconstructValues}
                      desconstructAudit={desconstructAudit}
                      cellType="pacing"
                    />

                    {/* Escalate */}
                    <DailyTaskCell
                      dayNumber={0}
                      department={department}
                      tasks={tasks}
                      departments={departments}
                      project={project}
                      hideApproveDecline={hideApproveDecline}
                      auth={auth}
                      deconstructTask={deconstructTask}
                      deconstructValues={deconstructValues}
                      desconstructAudit={desconstructAudit}
                      cellType="escalate"
                    />

                    {/* Due */}
                    <div
                      className={`project-view-page-body-row-task-cell ${
                        tasks.some(
                          (task) =>
                            task.department.id === department.id &&
                            task.dueDate !== null &&
                            typeof task.dueDate !== 'object' &&
                            task.delay < 3 &&
                            moment(task.dueDate).format('YYYY-MM-DD') <=
                              moment().add(-1, 'days').format('YYYY-MM-DD'),
                        )
                          ? ''
                          : 'no-tasks'
                      }`}
                    >
                      <div className="project-view-page-body-row-header-responsive-1">
                        <div>
                          DUE
                        </div>
                      </div>
                      <div className="project-view-page-body-row-task-cell-tasks">
                        {tasks
                          .filter((task) => task.department.id === department.id)
                          .map((task) => {
                            if (
                              task.dueDate !== null &&
                              typeof task.dueDate !== 'object' &&
                              task.delay < 3
                            ) {
                              if (
                                moment(task.dueDate).format('YYYY-MM-DD') <=
                                moment().add(-1, 'days').format('YYYY-MM-DD')
                              ) {
                                /* console.log('TaskID:', task.id); */
                                return (
                                  <div key={task.id}>
                                    <Task
                                      task={task}
                                      department={department}
                                      departments={departments}
                                      project={project}
                                      hideApproveDecline={hideApproveDecline}
                                      deconstructTask={deconstructTask}
                                      deconstructValues={deconstructValues}
                                      desconstructAudit={desconstructAudit}
                                    />
                                  </div>
                                );
                              }
                            }
                            return null;
                          })}
                      </div>
                    </div>
                    {[...Array(14)].map((_, index) => (
                      <DailyTaskCell
                        dayNumber={index}
                        department={department}
                        tasks={tasks}
                        departments={departments}
                        project={project}
                        hideApproveDecline={hideApproveDecline}
                        auth={auth}
                        deconstructTask={deconstructTask}
                        deconstructValues={deconstructValues}
                        desconstructAudit={desconstructAudit}
                        key={index}
                        cellType="daily"
                      />
                    ))}
                    {/* Empty space for the column view styling */}
                    <div className="project-view-page-body-row-task-cell no-tasks empty-space-task-cell"></div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  department: state.department,
  task: state.task,
  huddleBoard: state.huddleBoard,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, {
    getProject,
    getProjectTasks,    
    clearProjectError,
    getDepartments,
    updateHuddleBoard,
    fetchHuddleBoards,
  }),
)(ProjectView);
