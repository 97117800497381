import * as Yup from 'yup';

export const taskFormSchema = Yup.object({
  title: Yup.string()
    .min(3, '"Task Title" must be 5 characters at minimum')
    .max(140, '"Task Title" must be 140 characters or less')
    .required('"Task Title" is required'),
  description: Yup.string()
    .min(3, '"Task Description" must be 5 characters at minimum')
    .max(1000, '"Task Description" must be 140 characters or less'),    
  dueDate: Yup.date(),    
  complete: Yup.boolean(),
  assignedTo: Yup.string(),
  department: Yup.string().required(),
  // project: Yup.string().required(),
  // approved: Yup.boolean(),
  delay: Yup.number()
});
