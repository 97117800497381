import React, { useMemo } from 'react';
import { normalizeYearsDataWithMaster } from '../../../constants/functions';
import '../Metrica/Metrica.css';
import { month } from '../../../constants/index';
const MetricDataDaily = ({ huddleBoard, title, labels, selectedDate }) => {
  const currentDate = useMemo(() => selectedDate || new Date(), [selectedDate]);
  const currentYear = useMemo(() => currentDate.getFullYear().toString(), [currentDate]);
  const currentMonth = useMemo(() => month[currentDate.getMonth()], [currentDate]);

  // const lastDayOfMonth = useMemo(
  //   () => new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  //   [currentDate],
  // );
  // const daysInMonth = useMemo(() => lastDayOfMonth.getDate(), [lastDayOfMonth]);

  const metricsData = useMemo(() => {
    // return huddleBoard?.data[0]?.years?.[currentYear]?.[currentMonth] || noDataArray;
    return normalizeYearsDataWithMaster(huddleBoard?.data, selectedDate);
  }, [huddleBoard, selectedDate]);

  const metrics = useMemo(() => {
    // return huddleBoard?.data[0]?.years?.[currentYear]?.[currentMonth] || noDataArray;
    return metricsData[0].years[currentYear][currentMonth][currentDate.getDate()-1];
  }, [currentYear, currentMonth, metricsData]);

  // const metrics = huddleBoard?.data?.[0]?.years?.['2024']?.['Nov'] || [];

  const getDynamicMetrics = () => {

    let counts = {};

    if (metrics.length === 0) {
      counts = {
        truthy: 0,
        falsy: metricsData[1].master.items.length
      };
      return [
        {
          color: '#4CAF50',
          label: labels[0],
          value: `${counts.truthy} (${((counts.truthy / counts.falsy) * 100).toFixed(2)}%)`,
        },
        {
          color: '#FF7043',
          label: labels[1],
          value: `${counts.falsy} (${((counts.falsy / counts.falsy) * 100).toFixed(2)}%)`,
        }
      ];
    } else {
      if (title === 'Daily Agenda') {
        counts = {
          truthy: metrics.filter((item) => item.status?.includes('completed')).length,
          falsy: metrics.filter((item) => item.status?.includes('not completed')).length,        
        };
      }
      if (title === 'Attendance') {
        counts = {
          truthy: metrics.filter((item) => item.status?.includes('present')).length,
          falsy: metrics.filter((item) => item.status?.includes('absent')).length,        
        };
      }
      return [
        {
          color: '#4CAF50',
          label: labels[0],
          value: `${counts.truthy} (${((counts.truthy / metrics.length) * 100).toFixed(2)}%)`,
        },
        {
          color: '#FF7043',
          label: labels[1],
          value: `${counts.falsy} (${((counts.falsy / metrics.length) * 100).toFixed(2)}%)`,
        }
      ];
    }    
    
  };

  return (
    <div className="control-metrics-data-row">      
      {getDynamicMetrics().map((metric, idx) => (
        <div className="control-metrics-data-item" key={idx}>
          <div className="item-left-side">
            <div className="color-box" style={{ backgroundColor: metric.color }}></div>
            <span>{metric.label}</span>
          </div>
          <div className="item-right-side">
            <span>
              {metric.value}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
export default MetricDataDaily;
