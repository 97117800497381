import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';

// import moment from 'moment-timezone';

const DailyAgendaDeepDive = ({ huddleBoard, updateHuddleBoard, normalizedData }) => {
  
    const [formVisibility, setFormVisibility] = useState([false, false, false, false, false]); 

    const validationSchema = Yup.object({
        monday: Yup.string(),
        tuesday: Yup.string(),
        wednesday: Yup.string(),
        thursday: Yup.string(),
        friday: Yup.string(),
    });
    
    const formik = useFormik({
        initialValues: {
            monday: normalizedData[1].master?.subItems.monday || '',
            tuesday: normalizedData[1].master?.subItems.tuesday || '',
            wednesday: normalizedData[1].master?.subItems.wednesday || '',
            thursday: normalizedData[1].master?.subItems.thursday || '',
            friday: normalizedData[1].master?.subItems.friday || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            updateHuddleBoard(huddleBoard.id, 'previous', {
                ...huddleBoard,
                data: [
                    huddleBoard.data[0],
                    {
                        master: {
                            ...huddleBoard.data[1].master,
                            subItems: values
                        }
                    }
                ]
            });
            setFormVisibility([false, false, false, false, false]);
          // resetForm();
        },
    });
  
    const handleFormVisibilityToggle = (idx) => {        
        // setFormVisibility(formVisibility.map((value, index) => index === idx ? !value : value));


        const newVisibility = [...formVisibility];
        if (newVisibility[idx] === false) {
            if (idx === 0) {
                newVisibility[1] = false;
                formik.setFieldValue('tuesday', normalizedData[1].master?.subItems.tuesday);
                newVisibility[2] = false;
                formik.setFieldValue('wednesday', normalizedData[1].master?.subItems.wednesday);
                newVisibility[3] = false;
                formik.setFieldValue('thursday', normalizedData[1].master?.subItems.thursday);
                newVisibility[4] = false;
                formik.setFieldValue('friday', normalizedData[1].master?.subItems.friday);
            } else if (idx === 1) {
                newVisibility[0] = false;
                formik.setFieldValue('monday', normalizedData[1].master?.subItems.monday);
                newVisibility[2] = false;
                formik.setFieldValue('wednesday', normalizedData[1].master?.subItems.wednesday);
                newVisibility[3] = false;
                formik.setFieldValue('thursday', normalizedData[1].master?.subItems.thursday);
                newVisibility[4] = false;
                formik.setFieldValue('friday', normalizedData[1].master?.subItems.friday);
            } else if (idx === 2) {
                newVisibility[0] = false;
                formik.setFieldValue('monday', normalizedData[1].master?.subItems.monday);
                newVisibility[1] = false;
                formik.setFieldValue('tuesday', normalizedData[1].master?.subItems.tuesday);
                newVisibility[3] = false;
                formik.setFieldValue('thursday', normalizedData[1].master?.subItems.thursday);
                newVisibility[4] = false;
                formik.setFieldValue('friday', normalizedData[1].master?.subItems.friday);
            } else if (idx === 3) {
                newVisibility[0] = false;
                formik.setFieldValue('monday', normalizedData[1].master?.subItems.monday);
                newVisibility[1] = false;
                formik.setFieldValue('tuesday', normalizedData[1].master?.subItems.tuesday);
                newVisibility[2] = false;
                formik.setFieldValue('wednesday', normalizedData[1].master?.subItems.wednesday);
                newVisibility[4] = false;
                formik.setFieldValue('friday', normalizedData[1].master?.subItems.friday);
            } else if (idx === 4) {
                newVisibility[0] = false;
                formik.setFieldValue('monday', normalizedData[1].master?.subItems.monday);
                newVisibility[1] = false;
                formik.setFieldValue('tuesday', normalizedData[1].master?.subItems.tuesday);
                newVisibility[2] = false;
                formik.setFieldValue('wednesday', normalizedData[1].master?.subItems.wednesday);
                newVisibility[3] = false;
                formik.setFieldValue('thursday', normalizedData[1].master?.subItems.thursday);
            }
        } else {
            if (idx === 0) {
                formik.setFieldValue('monday', normalizedData[1].master?.subItems.monday);
            } else if (idx === 1) {
                formik.setFieldValue('tuesday', normalizedData[1].master?.subItems.tuesday);        
            } else if (idx === 2) {
                formik.setFieldValue('wednesday', normalizedData[1].master?.subItems.wednesday);
            } else if (idx === 3) {
                formik.setFieldValue('thursday', normalizedData[1].master?.subItems.thursday);
            } else if (idx === 4) {
                formik.setFieldValue('friday', normalizedData[1].master?.subItems.friday);
            }
        }
        newVisibility[idx] = !newVisibility[idx];
        setFormVisibility(newVisibility);
    };
  

    return (
        <div className="daily-agenda-deep-dive">
            <h2 className="huddle-board-content__sub-title title text-center">Deep Dive Topics</h2>
            <div>
                <div className="daily-agenda-deep-dive-table">
                    <form
                        className={'daily-agenda-deep-dive-edit-form edit-form'}
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="deep-dive-row">
                            <div className="deep-dive-heading">Monday</div>
                            <div className="deep-dive-content">
                                {!formVisibility[0] ? (
                                    <div className="deep-dive-content-row">
                                        {/* <p className="huddle-board-content__description description"> */}
                                            {normalizedData[1].master?.subItems.monday}
                                        {/* </p> */}
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            className="huddle-board-content__description title-edit-icon"
                                            onClick={() => handleFormVisibilityToggle(0)}
                                        />
                                    </div>
                                ) : (
                                <div className="deep-dive-content-edit">                                    
                                    {/* <label htmlFor="description" className="sr-only">
                                        Edit Description
                                    </label> */}
                                    <textarea
                                        rows={3}
                                        wrap="soft"
                                        id="monday"
                                        name="monday"
                                        placeholder="Enter topic"
                                        value={formik.values.monday}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="edit-form-buttons">
                                        <button type="submit" className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faCheck}
                                            className="huddle-board-content__description title-edit-save-icon"
                                            />
                                        </button>
                                        <button className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faXmark}
                                            className="huddle-board-content__description title-edit-cancel-icon"
                                            onClick={() => handleFormVisibilityToggle(0)}
                                            />
                                        </button>
                                    </div>                                    
                                </div>
                                )}
                            </div>
                        </div>

                        <div className="deep-dive-row">
                            <div className="deep-dive-heading">Tuesday</div>
                            <div className="deep-dive-content">
                                {!formVisibility[1] ? (
                                    <div className="deep-dive-content-row">
                                        {/* <p className="huddle-board-content__description description"> */}
                                            {normalizedData[1].master?.subItems.tuesday}
                                        {/* </p> */}
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            className="huddle-board-content__description title-edit-icon"
                                            onClick={() => handleFormVisibilityToggle(1)}
                                        />
                                    </div>
                                ) : (
                                <div className="deep-dive-content-edit">                                    
                                    <label htmlFor="description" className="sr-only">
                                        Edit Description
                                    </label>
                                    <textarea                                        
                                        rows={3}
                                        wrap="soft"
                                        id="tuesday"
                                        name="tuesday"
                                        placeholder="Enter Description"
                                        value={formik.values.tuesday}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="edit-form-buttons">
                                        <button type="submit" className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faCheck}
                                            className="huddle-board-content__description title-edit-save-icon"
                                            />
                                        </button>
                                        <button className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faXmark}
                                            className="huddle-board-content__description title-edit-cancel-icon"
                                            onClick={() => handleFormVisibilityToggle(1)}
                                            />
                                        </button>
                                    </div>                                    
                                </div>
                                )}
                            </div>
                        </div>

                        <div className="deep-dive-row">
                            <div className="deep-dive-heading">Wednesday</div>
                            <div className="deep-dive-content">
                                {!formVisibility[2] ? (
                                    <div className="deep-dive-content-row">
                                        {/* <p className="huddle-board-content__description description"> */}
                                            {normalizedData[1].master?.subItems.wednesday}
                                        {/* </p> */}
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            className="huddle-board-content__description title-edit-icon"
                                            onClick={() => handleFormVisibilityToggle(2)}
                                        />
                                    </div>
                                ) : (
                                <div className="deep-dive-content-edit">                                    
                                    <label htmlFor="description" className="sr-only">
                                        Edit Description
                                    </label>
                                    <textarea
                                        rows={3}
                                        wrap="soft"
                                        id="wednesday"
                                        name="wednesday"
                                        placeholder="Enter Description"
                                        value={formik.values.wednesday}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="edit-form-buttons">
                                        <button type="submit" className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faCheck}
                                            className="huddle-board-content__description title-edit-save-icon"
                                            />
                                        </button>
                                        <button className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faXmark}
                                            className="huddle-board-content__description title-edit-cancel-icon"
                                            onClick={() => handleFormVisibilityToggle(2)}
                                            />
                                        </button>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>

                        <div className="deep-dive-row">
                            <div className="deep-dive-heading">Thursday</div>
                            <div className="deep-dive-content">
                                {!formVisibility[3] ? (
                                    <div className="deep-dive-content-row">
                                        {/* <p className="huddle-board-content__description description"> */}
                                            {normalizedData[1].master?.subItems.thursday}
                                        {/* </p> */}
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            className="huddle-board-content__description title-edit-icon"
                                            onClick={() => handleFormVisibilityToggle(3)}
                                        />
                                    </div>
                                ) : (
                                <div className="deep-dive-content-edit">                                    
                                    <label htmlFor="description" className="sr-only">
                                        Edit Description
                                    </label>
                                    <textarea
                                        rows={3}
                                        wrap="soft"
                                        id="thursday"
                                        name="thursday"
                                        placeholder="Enter Description"
                                        value={formik.values.thursday}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="edit-form-buttons">
                                        <button type="submit" className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faCheck}
                                            className="huddle-board-content__description title-edit-save-icon"
                                            />
                                        </button>
                                        <button className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faXmark}
                                            className="huddle-board-content__description title-edit-cancel-icon"
                                            onClick={() => handleFormVisibilityToggle(3)}
                                            />
                                        </button>
                                    </div>                                    
                                </div>
                                )}
                            </div>
                        </div>

                        <div className="deep-dive-row">
                            <div className="deep-dive-heading">Friday</div>
                            <div className="deep-dive-content">
                                {!formVisibility[4] ? (
                                    <div className="deep-dive-content-row">
                                        {/* <p className="huddle-board-content__description description"> */}
                                            {normalizedData[1].master?.subItems.friday}
                                        {/* </p> */}
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            className="huddle-board-content__description title-edit-icon"
                                            onClick={() => handleFormVisibilityToggle(4)}
                                        />
                                    </div>
                                ) : (
                                <div className="deep-dive-content-edit">                                    
                                    <label htmlFor="description" className="sr-only">
                                        Edit Description
                                    </label>
                                    <textarea
                                        rows={3}
                                        wrap="soft"
                                        id="friday"
                                        name="friday"
                                        placeholder="Enter Description"
                                        value={formik.values.friday}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="edit-form-buttons">
                                        <button type="submit" className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faCheck}
                                            className="huddle-board-content__description title-edit-save-icon"
                                            />
                                        </button>
                                        <button className="edit-submit-button">
                                            <FontAwesomeIcon
                                            icon={faXmark}
                                            className="huddle-board-content__description title-edit-cancel-icon"
                                            onClick={() => handleFormVisibilityToggle(4)}
                                            />
                                        </button>
                                    </div>                                   
                                </div>
                                )}
                            </div>
                        </div>
                    </form>


                </div>
                
                
                
            
            </div>
        </div>
    );

};

export default DailyAgendaDeepDive;
