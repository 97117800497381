import React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';

import { addProject } from '../../store/actions/projectActions';
import { projectFormSchema } from './validation';

import './styles.css';

const ProjectForm = ({ addProject, project: { projects } }) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      projectPlan: '',
      risks: '',
      opportunities: '',
      resources: '',
      fontStyle: 'Reenie-Beanie',
      fontWeight: 'bold',
      fontSizeLarge: '37px',
      fontSizeSmall: '25px',
    },
    validationSchema: projectFormSchema,
    onSubmit: (values, { resetForm }) => {
      addProject({
        title: values.title,
        projectPlan: values.projectPlan,
        risks: values.risks,
        opportunities: values.opportunities,
        resources: values.resources,
        fontStyle: values.fontStyle,
        fontWeight: values.fontWeight,
        fontSizeLarge: values.fontSizeLarge,
        fontSizeSmall: values.fontSizeSmall,
      });
      resetForm();
    },
  });

  const isSubmiting = projects.some((p) => p.id === 0);

  return (
    <div className="project-form">
      <h2>Add a New Pod</h2>
      <form onSubmit={formik.handleSubmit}>
        {/* <textarea
          name="title"
          cols="30"
          rows="1"
          placeholder="Project title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
          disabled={isSubmiting}
        /> */}

        <h4 className="label">Pod Title</h4>
        {/* Project Title */}
        <input
          name="title"
          // rows="1"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
          disabled={isSubmiting}
        />
        {/* {(formik.touched.title && formik.errors.title) || project.error ? (
          <p className="error">{formik.errors.title || project.error}</p>
        ) : null} */}

        {/* <h4 className='label'>Project Plan Link</h4> */}
        {/* Project Plan */}
        {/* <input
          name="projectPlan"
          // rows="1"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.projectPlan}
          disabled={isSubmiting}
        />             */}
        {/* {(formik.touched.projectPlan && formik.errors.projectPlan) || project.error ? (
          <p className="error">{formik.errors.projectPlan || project.error}</p>
        ) : null}             */}

        {/* <h4 className='label'>Risks Link</h4> */}
        {/* Risks */}
        {/* <input
          name="risks"
          // rows="1"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.risks}
          disabled={isSubmiting}
        />             */}
        {/* {(formik.touched.risks && formik.errors.risks) || project.error ? (
          <p className="error">{formik.errors.risks || project.error}</p>
        ) : null} */}

        {/* <h4 className='label'>Opportunities Link</h4> */}
        {/* Opportunities */}
        {/* <input
          name="opportunities"
          // rows="1"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.opportunities}
          disabled={isSubmiting}
        />             */}
        {/* {(formik.touched.opportunities && formik.errors.opportunities) || project.error ? (
          <p className="error">{formik.errors.opportunities || project.error}</p>
        ) : null} */}

        {/* <h4 className='label'>Resources Link</h4> */}
        {/* Resources */}
        {/* <input
          name="resources"
          // rows="1"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.resources}
          disabled={isSubmiting}
        />            
        {/* {(formik.touched.resources && formik.errors.resources) || project.error ? (
          <p className="error">{formik.errors.resources || project.error}</p>
        ) : null} */}

        <h4 className="label">Task Settings</h4>

        <h5 className="label">Font Style</h5>

        <select
          name="fontStyle"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fontStyle}
          style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight }}
          disabled={isSubmiting}
          className="project-form-select"
        >
          <option
            value="Brush Script MT"
            style={{ fontFamily: 'Brush Script MT', fontWeight: formik.values.fontWeight }}
          >
            Brush Script MT
          </option>
          <option value="Courier New" style={{ fontFamily: 'Courier New', fontWeight: formik.values.fontWeight }}>
            Courier New
          </option>
          <option value="Garamond" style={{ fontFamily: 'Garamond', fontWeight: formik.values.fontWeight }}>
            Garamond
          </option>
          <option
            value="'Klyakson', sans-serif"
            style={{ fontFamily: "'Klyakson', sans-serif", fontWeight: formik.values.fontWeight }}
          >
            Klyakson
          </option>
          <option value="Lato" style={{ fontFamily: 'Lato', fontWeight: formik.values.fontWeight }}>
            Lato
          </option>
          <option value="Reenie-Beanie" style={{ fontFamily: 'Reenie-Beanie', fontWeight: formik.values.fontWeight }}>
            Reenie Beanie
          </option>
          <option
            value="'Roboto', sans-serif"
            style={{ fontFamily: "'Roboto', sans-serif", fontWeight: formik.values.fontWeight }}
          >
            Roboto
          </option>
          
        </select>

        <h5 className="label">Font Weight</h5>
        <select
          name="fontWeight"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fontWeight}
          style={{
            fontFamily: formik.values.fontStyle,
            fontWeight: formik.values.fontWeight            
          }}
          disabled={isSubmiting}
          className="project-form-select"
        >
          <option 
            value="bold"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: 'bold' }}
          >
            Bold
          </option>
          <option
            value="normal"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: 'normal' }}
          >
            Regular
          </option>
        </select>

        <h5 className="label">Font Size - Large Screen</h5>

        <select
          name="fontSizeLarge"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fontSizeLarge}
          style={{
            fontFamily: formik.values.fontStyle,
            fontWeight: formik.values.fontWeight,
            fontSize: formik.values.fontSizeLarge,
          }}
          disabled={isSubmiting}
          className="project-form-select"
        >
          <option
            value="25px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '25px' }}
          >
            XSmall
          </option>
          <option
            value="31px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '31px' }}
          >
            Small
          </option>
          <option
            value="37px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '37px' }}
          >
            Medium
          </option>
          <option
            value="43px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '43px' }}
          >
            Large
          </option>
          <option
            value="49px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '49px' }}
          >
            XLarge
          </option>
        </select>

        <h5 className="label">Font Size - Mobile Screen</h5>

        <select
          name="fontSizeSmall"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fontSizeSmall}
          style={{
            fontFamily: formik.values.fontStyle,
            fontWeight: formik.values.fontWeight,
            fontSize: formik.values.fontSizeSmall,
          }}
          disabled={isSubmiting}
          className="project-form-select"
        >
          <option
            value="17px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '17px' }}
          >
            XSmall
          </option>
          <option
            value="21px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '21px' }}
          >
            Small
          </option>
          <option
            value="25px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '25px' }}
          >
            Medium
          </option>
          <option
            value="29px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '29px' }}
          >
            Large
          </option>
          <option
            value="33px"
            style={{ fontFamily: formik.values.fontStyle, fontWeight: formik.values.fontWeight, fontSize: '33px' }}
          >
            XLarge
          </option>
        </select>

        <input type="submit" className="btn save-btn" value="Create Pod" disabled={isSubmiting} />
        {formik.touched.title && formik.errors.title ? (
          <p className="error">{formik.errors.title}</p>
        ) : null}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, { addProject })(ProjectForm);
