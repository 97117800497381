import React, { useCallback, useMemo } from 'react';
import { normalizeYearsData } from '../../../constants/functions';
// import { currentYear, currentDate, currentMonth, daysInMonth } from '../../../constants/index';
import { selectDate, selectYear, selectMonth, selectDaysInMonth } from '../../../constants/functions';
const LetterQ = ({ huddleBoard, selectedDate, setSelectedDate }) => {
  const data = useMemo(() => {
    return normalizeYearsData(huddleBoard?.data, selectedDate)[0].years[selectYear(selectedDate)][selectMonth(selectedDate)];
  }, [huddleBoard, selectedDate]);

  const renderRow = useCallback(
    (startIdx, endIdx, emptyCount = 0) => (
      <div className="row">
        {data.slice(startIdx, endIdx).map((el, idx) => {
          const globalIdx = startIdx + idx;

          return (
            <React.Fragment key={globalIdx}>
              {(globalIdx === 0 || globalIdx === 25) && (
                <>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {(globalIdx === 3 || globalIdx === 20) && (
                <>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {(globalIdx === 10 || globalIdx === 14 || globalIdx === 18) && (
                <>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {globalIdx === 28 && (
                <>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {/* Regular div */}
              <div
                className={` indicator ${
                  globalIdx === (Number(selectDate(selectedDate).getDate()) - 1) ? 'today' : ''
                }`}
                onClick={() => setSelectedDate(new Date(selectDate(selectedDate).setDate(globalIdx + 1)))}
                style={
                  el === 'true'
                    ? { backgroundColor: '#5BF98A' }
                    : el === 'false'
                    ? { backgroundColor: '#FC9797' }
                    : {}
                }
              >
                {globalIdx + 1}
              </div>
            </React.Fragment>
          );
        })}
        { (startIdx === 28 && endIdx == 28) ? (
          <>
            <div className="row-indicator empty"></div>
            <div className="row-indicator empty"></div>
            <div className="row-indicator empty"></div>
            <div className="row-indicator empty"></div>
          </>
        ) : null }
        {Array.from({ length: emptyCount }).map((_, idx) => (
          <div
            key={`empty-${idx}`}
            className="row-indicator indicator"
            style={{ backgroundColor: '#D9E4FF' }}
          />
        ))}
      </div>
    ),
    [data, selectedDate],
  );
  return (
    <>
      {renderRow(0, 3)}
      {renderRow(3, 8)}
      {renderRow(8, 12)}
      {renderRow(12, 16)}
      {renderRow(16, 20)}
      {renderRow(20, 25)}
      {renderRow(25, 28)}
      {selectDaysInMonth(selectedDate) === 28 && renderRow(28, 28, 3)}
      {selectDaysInMonth(selectedDate) === 29 && renderRow(28, 29, 2)}
      {selectDaysInMonth(selectedDate) === 30 && renderRow(28, 30, 1)}
      {selectDaysInMonth(selectedDate) === 31 && renderRow(28, 31, 0)}
    </>
  );
};
export default LetterQ;
