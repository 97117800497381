import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';
// import MessageList from '../../components/MessageList/MessageList';
// import ActiveProjectList from '../../components/ActiveProjectList/ActiveProjectList';
// import ArchivedProjectList from '../../components/ArchivedProjectList/ArchivedProjectList';
import DepartmentList from '../../components/DepartmentList/DepartmentList';
import DepartmentForm from '../../components/DepartmentForm/DepartmentForm';

import requireAuth from '../../hoc/requireAuth';
import { getProject } from '../../store/actions/projectActions';
import './styles.css';

const ProjectDepartmentsEdit = ({
  getProject,
  thisProject: { project, isLoading, error },
  history,
  match,
}) => {
  const matchProjectId = match.params.projectId;

  useEffect(() => {
    getProject(matchProjectId, true, history);
  }, [getProject, history, matchProjectId]);

  return (
    <Layout>
      <div className="departments-edit-page">
        {error && <div className="error-center">{error}</div>}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h1>
              {project.title} / <i>Departments</i>
            </h1>
            <div className="department-list-container">
              {/* Departments */}
              <DepartmentList projectId={matchProjectId} project={project} />
              <DepartmentForm projectId={matchProjectId} />
            </div>
            <div className="form-actions">
              <Link to={`/project/edit/${project.id}`} className="btn return-btn">
                Back to Pod
              </Link>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  thisProject: state.project,
});

// export default compose(requireAuth, withRouter, connect(mapStateToProps))(ProjectDepartmentsEdit);
export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getProject }),
)(ProjectDepartmentsEdit);
