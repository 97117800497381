import {
  GET_DEPARTMENTS_LOADING,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAIL,
  GET_DEPARTMENT_LOADING,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAIL,
  GET_DEPARTMENTS_PROFILE_LOADING,
  GET_DEPARTMENTS_PROFILE_SUCCESS,
  GET_DEPARTMENTS_PROFILE_FAIL,
  ADD_DEPARTMENT_LOADING,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAIL,
  DELETE_DEPARTMENT_LOADING,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAIL,
  EDIT_DEPARTMENT_LOADING,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_FAIL,
  CLEAR_DEPARTMENT_ERROR,
} from '../types';

const initialState = {
  departments: [],
  department: {},
  isLoading: false,
  error: null,
  departmentProfile: null,
};

// You could have an array [{ id: 1, isLoading: false, error: null, text: "Hey" }, { id: 2, isLoading: true, error: null, text: null }]

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_DEPARTMENTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEPARTMENT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEPARTMENTS_PROFILE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_DEPARTMENT_LOADING:
      return {
        ...state,
        departments: [
          {
            id: 0,
            name: 'Loading...',
            color: '#000000',
            viewOnly: false,
            external: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            users: { ...payload.department.users },
            project: { ...payload.department.project },
          },
          ...state.departments,
        ],
      };
    case DELETE_DEPARTMENT_LOADING:
    case EDIT_DEPARTMENT_LOADING:
      return {
        ...state,
        departments: state.departments.map((d) => {
          if (d.id === payload.id) return { ...d, isLoading: true };
          return d;
        }),
      };
    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        departments: payload.departments,
      };
    case GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        department: payload.department,
      };
    case GET_DEPARTMENTS_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        departmentProfile: payload.departmentProfile,
      };
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.map((d) => {
          if (d.id === 0) return payload.department;
          return d;
        }),
      };
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.filter((d) => d.id !== payload.department.id),
      };
    case EDIT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.map((d) => {
          if (d.id === payload.department.id) return payload.department;
          return d;
        }),
      };
    case DELETE_DEPARTMENT_FAIL:
    case EDIT_DEPARTMENT_FAIL:
      return {
        ...state,
        error: null,
        departments: state.departments.map((d) => {
          if (d.id === payload.id) return { ...d, isLoading: false, error: payload.error };
          return d;
        }),
      };
    case GET_DEPARTMENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_DEPARTMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_DEPARTMENTS_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case ADD_DEPARTMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
        departments: state.departments.filter((d) => d.id !== 0),
      };
    case CLEAR_DEPARTMENT_ERROR:
      return {
        ...state,
        departments: state.departments.map((d) => {
          if (d.id === payload.id) return { ...d, isLoading: false, error: null };
          return d;
        }),
      };
    default:
      return state;
  }
}
