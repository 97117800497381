import React, { useEffect, useState, useMemo } from 'react';
import MetricData from './MetricData';
// import StatusCard from './StatusCard/StatusCard';
import { month, colors } from '../../../constants/index';
import { normalizeYearsData, normalizeWeeklyDataWithMaster } from '../../../constants/functions';
import { Bar, Pie, Line } from 'react-chartjs-2';
import Loader from '../../Loader/Loader';
import Chart from 'chart.js/auto';
import moment from 'moment-timezone';
import './Metrica.css';

const MetricWeekly = ({ huddleBoard, title, updateHuddleBoard, labels, selectedDate }) => {
  const currentDate = useMemo(
    () => selectedDate || new Date(moment().startOf('isoWeek').toISOString()),
    [selectedDate],
  );
  const currentYear = useMemo(() => currentDate.getFullYear().toString(), [currentDate]);
  
  //Chart
  const formatChartData = (huddleBoardData) => {
    if (huddleBoardData) {
      let newChartData;
      if (huddleBoardData.title === 'Sponsor Report') {
        const statusCounts = [...labels, 'no-data'].map(
          (label) => huddleBoardData.data.filter((item) => item.statusValue === label).length,
        );
        return {
          labels,
          datasets: [
            {
              label: 'Metrics',
              data: statusCounts,
              backgroundColor: [
                colors.acceptColor,
                colors.yellowColor,
                colors.dangerColor,
                colors.grayColor,
              ],
            },
          ],
        };
      } else if (huddleBoardData.title === 'Team Pulse Check') {        
        // Count total number of weeks
        let chartLabels = [];
        
        let weeks = normalizeWeeklyDataWithMaster(huddleBoardData.data)[0].weeks;
        
        let sortedWeeks = weeks.sort((a, b) => {
          return moment(a.week).diff(moment(b.week));
        })

        let counts = sortedWeeks.map((week, index) => {   
          // chartLabels.push(week.week);
          chartLabels.push(week.week);
          if (week.questions.length > 0) {            
            
            let sums = week.questions.reduce((accumulator, val)=> {return (val.rating ? accumulator + val.rating : accumulator)}, 0);
            let ratingsCounts = {
              Five: week.questions.filter((el) => el.rating === 5).length,
              Four: week.questions.filter((el) => el.rating === 4).length,
              Three: week.questions.filter((el) => el.rating === 3).length,
              Two: week.questions.filter((el) => el.rating === 2).length,
              One: week.questions.filter((el) => el.rating === 1).length,              
            };

            return {
              x: week.week,
              y: (sums / (ratingsCounts.Five + ratingsCounts.Four + ratingsCounts.Three + ratingsCounts.Two + ratingsCounts.One)).toFixed(2)
            };
            
            
          } else {
            return {
              x: week.week,
              y: 0
            };
          }
        });

        // Prepare chart data
        let newChartData = {        
          labels: chartLabels,
          datasets: [
            {
              label: "Average Rating",
              data: counts,
              backgroundColor: '#4CAF50',
              borderColor: '#4CAF50',
            }
          ],
        };

        // Update state to re-render the chart
        return newChartData;
      } else {
        // const data = huddleBoardData?.data[0]?.years?.['2024'] || {};
        const data = normalizeYearsData(huddleBoard?.data, selectedDate)[0].years[currentYear];
        // console.log(data);

        // Count values for each month
        let counts = month.map((mon) => {
          let days = data[mon] || [];
          return {
            mon,
            true: days.filter((day) => day === 'true').length,
            false: days.filter((day) => day === 'false').length,
            noData: days.filter((day) => day === 'no-data').length,
          };
        });

        // Prepare chart data
        newChartData = {
          labels: month,
          datasets: [
            {
              label: labels[0],
              data: counts.map((item) => item.true),
              backgroundColor: '#4CAF50',
            },
            {
              label: labels[1],
              data: counts.map((item) => item.false),
              backgroundColor: '#FF7043',
            },
            {
              label: 'No Data',
              data: counts.map((item) => item.noData),
              backgroundColor: '#D9E4FF',
            },
          ],
        };
      }

      // Update state to re-render the chart
      return newChartData;
    }
  }; 

  return (
    <>
      <div className={`huddle-board-content-metric ${title === 'Sponsor Report' ? 'sponsor-report-metric' : ''} ${title === 'Team Pulse Check' ? 'team-pulse-check-metric' : ''}`}>
        <div className="control-metrics-data">
          <h3>{title === 'Team Pulse Check' ? 'Selected week\'s metrics:' : 'Metrics to date:'}</h3>
          {title === 'Sponsor Report' ? (
            <MetricData
              huddleBoard={huddleBoard}
              labels={[...labels, 'No data']}
              title={title}
              selectedDate={selectedDate}
            />            
          ) : title === 'Team Pulse Check' ? (
            <MetricData
              huddleBoard={huddleBoard}
              labels={[...labels, 'Not rated', 'Average']}
              title={title}
              selectedDate={selectedDate}
            />            
          ) : (
            <MetricData
              huddleBoard={huddleBoard}
              labels={labels}
              title={title}
              selectedDate={selectedDate}
            />
          )}
        </div>
        <div
          className=" huddle-board-content-graph chart-container"
          // style={{ height: '400px', width: '100%' }}
        >
          {huddleBoard ? (
            huddleBoard.title === 'Sponsor Report' ? (
              <Pie
                data={formatChartData(huddleBoard)}
                options={{
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  /* scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                      beginAtZero: true,
                    },
                  }, */
                }}
              />              
            ) : huddleBoard.title === 'Team Pulse Check' ? (
              <Line
                data={formatChartData(huddleBoard)}
                options={{
                  plugins: {
                    legend: {
                      position: 'top',
                      font: {
                        size: 48
                      }
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      type: 'linear',
                      min: 0,
                      max: 5,
                      beginAtZero: true,
                    },
                  },
                }}
              />              
            ) : (
              <Bar
                data={formatChartData(huddleBoard)}
                options={{
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                      beginAtZero: true,
                    },
                  },
                }}
              />
            )
          ) : (
            <div>Loading chart...</div>
          )}
        </div>
        {/* <div className="huddle-board-content-controls">
          <StatusCard
            updateHuddleBoard={updateHuddleBoard}
            huddleBoard={huddleBoard}
            title={title}
            labels={labels.slice(0, 2)}
            selectedDate={selectedDate}
          />
        </div> */}
      </div>
    </>
  );
};
export default MetricWeekly;
