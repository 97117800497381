import React, { useState, useEffect } from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';

// import moment from 'moment-timezone';

const DailyAgenda = ({ rows, isGlobalEdit, deleteRow, editRow, markCompleteRow }) => {

  const [displayedRows, setDisplayedRows] = useState([]);
  
  useEffect(() => {    
    setDisplayedRows(rows.map((row, originalIndex) => ({ ...row, originalIndex })));        
  }, [rows, isGlobalEdit])
//   console.log("data", data);
//   console.log("master", master);

  return (
    <>
        <div className="huddle-board-content__daily-agenda daily-agenda">
            {/* <h2 className="huddle-board-content__title title text-center">{title}</h2> */}

            <div className="table-wrapper">
                <table className="form-daily-agenda__table table">
                    <thead>
                        <tr>
                            <th className="da_actions">Actions</th>
                            <th className="da_topic">Topics</th>
                            <th className="da_duration">Duration</th>
                            <th className="da_status">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedRows.map((row, idx) => {
                            return (
                                <tr key={idx} className={`${row?.status?.includes('completed') ? 'completed' : ''}`}>
                                    <td className="da_actions">
                                        <span className="actions">
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                className="edit-btn"
                                                onClick={() => editRow(row.originalIndex)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className=" delete-btn"
                                                onClick={() => deleteRow(row.originalIndex)}
                                            />
                                        </span>
                                    </td>
                                    <td className="da_topic">{row?.topic}</td>
                                    <td className="da_duration">{row?.duration}</td>
                                    <td className="da_status">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={`complete-btn ${row?.status?.includes('completed') ? 'completed' : ''}`}
                                            onClick={() => markCompleteRow(row.originalIndex)}
                                        />
                                    </td>                        
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="global-edit-indicator">
                {isGlobalEdit ? 
                    (                    
                        <div className="global-edit-indicator__true">* Editing <b>Global Template</b> of Agenda</div>                    
                    ) : (
                        <div className="global-edit-indicator__false">* Editing <b>Selected</b> Day's Agenda</div>
                    )
                }
            </div>
        </div>
    </>
  );

};

export default DailyAgenda;
