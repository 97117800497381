import React, { useCallback, useMemo } from 'react';
import { normalizeYearsData } from '../../../constants/functions';
// import { currentDate, currentYear, currentMonth, daysInMonth } from '../../../constants/index';
import { selectDate, selectYear, selectMonth, selectDaysInMonth } from '../../../constants/functions';
const LetterS = ({ huddleBoard, selectedDate, setSelectedDate }) => {
  // const yesterdayIndex = useMemo(() => currentDate.getDate() - 1, []);
  // currentDate, currentYear, currentMonth, daysInMonth
  const data = useMemo(() => {
    return normalizeYearsData(huddleBoard?.data, selectedDate)[0].years[selectYear(selectedDate)][selectMonth(selectedDate)];
  }, [huddleBoard, selectedDate]);
  const renderRow = useCallback(
    (startIdx, endIdx, emptyCount = 0) => (
      <div className="row">
        {data.slice(startIdx, endIdx).map((el, idx) => {
          const globalIdx = startIdx + idx;

          return (
            <React.Fragment key={globalIdx}>
              {globalIdx === 7 && (
                <>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {globalIdx === 25 && (
                <>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {globalIdx === 21 && (
                <>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {/* Regular div */}
              <div
                className={` indicator ${
                  globalIdx === (Number(selectDate(selectedDate).getDate()) - 1) ? 'today' : ''
                }`}
                onClick={() => setSelectedDate(new Date(selectDate(selectedDate).setDate(globalIdx + 1)))}
                style={
                  el === 'true'
                    ? { backgroundColor: '#5BF98A' }
                    : el === 'false'
                    ? { backgroundColor: '#FC9797' }
                    : {}
                }
              >
                {globalIdx + 1}
              </div>
            </React.Fragment>
          );
        })}
        {Array.from({ length: emptyCount }).map((_, idx) => (
          <div
            key={`empty-${idx}`}
            className="row-indicator indicator"
            style={{ backgroundColor: '#D9E4FF' }}
          />
        ))}
      </div>
    ),
    [data, selectedDate],
  );
  return (
    <>
      {renderRow(0, 5)}
      {renderRow(5, 9)}
      {renderRow(9, 11)}
      {renderRow(11, 16)}
      {renderRow(16, 21)}
      {renderRow(21, 23)}
      {renderRow(23, 27)}
      {selectDaysInMonth(selectedDate) === 28 && renderRow(27, 28, 4)}
      {selectDaysInMonth(selectedDate) === 29 && renderRow(27, 29, 3)}
      {selectDaysInMonth(selectedDate) === 30 && renderRow(27, 30, 2)}
      {selectDaysInMonth(selectedDate) === 31 && renderRow(27, 31, 1)}
    </>
  );
};
export default LetterS;
