// import React, { useEffect, useState } from 'react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import HuddleBoardContent from './HuddleBoardContent';
import './styles.css';
import HuddleBoardForm from './Form/HuddleBoardForm';
import Loader from '../Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faChartGantt,
  faTriangleExclamation,
  faClover,
  faHeartPulse,
  faMagnifyingGlassDollar,
  faCalendarCheck,
  faCalendarWeek,
  faMedal,
  faTableList,
  faClipboardUser,
} from '@fortawesome/free-solid-svg-icons';

const HuddleBoards = ({
  huddleBoards,
  toggleHuddleBoardVisibility,
  projectId,
  // updateHuddleBoard,
  error,
  isLoading,
  projectPlan,
  projectRisks,
  projectOpportunities,
}) => {
  const [isHuddleBoardVisible, setIsHuddleBoardVisible] = useState({});

  const toggleHuddleBoardCenterVisibility = (index) => {
    // -1 means close all huddle boards when overlay is clicked
    if (index === -1) {
      let trueIndex = -1;
      for (const key in isHuddleBoardVisible) {
        if (isHuddleBoardVisible.hasOwnProperty(key)) {
          trueIndex = key;
        }
      }
      setIsHuddleBoardVisible((prevState) => ({
        ...prevState,
        [trueIndex]: false,
      }));
    } else {
      setIsHuddleBoardVisible((prevState) => ({
        // ...prevState,
        [index]: !prevState[index],
      }));
    }
  };

  const isAnyHuddleBoardActive = () => {
    // console.log(isHuddleBoardVisible);
    for (const key in isHuddleBoardVisible) {
      if (isHuddleBoardVisible.hasOwnProperty(key)) {
        if (isHuddleBoardVisible[key] === true) return true;
      }
    }
    return false;
  };

  const getFilteredHuddleBoard = (title) => {
    return huddleBoards?.find((el) => el.title === title);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <span>Error occured {error.message}</span>;
  }

  // Huddle Boards Mapping:
  // 0: I (Project Info) - Client & Patient Centricity
  // 1: PP (Project Plan) - Project Plan Key Milestones
  // 2: R (Risks)
  // 3: O (Opportunities)
  // 4: TP (Team Pulse Check)
  // 15: SR (Sponsor Review) - Sponsor Report Out Project Health
  // 5: WP (Weekly Priorities)
  // 6: GM (Recognition) - Golden Moments
  // 7: DA (Daily Agenda)
  // 8: NTA (Actions) - Non-TIP Actions
  // 9: A (Attendance)
  // 10: S (Safety)
  // 11: Q (Quality)
  // 12: C (Cost)
  // 13: P (People)
  // 14: D (Delivery)
  //

  return (
    <>
      {/* Background Overlay */}
      {isAnyHuddleBoardActive() && (
        <div className="huddle-boards-background-overlay" onClick={() => toggleHuddleBoardCenterVisibility(-1)} ></div>
      )}

      {/* Left Container */}
      <div
        className={`huddle-boards-left-container ${
          isAnyHuddleBoardActive() ? 'huddle-board-is-active' : ''
        }`}
      >
        <div className="huddle-boards-group-title">Project Overview</div>
        <div
          className={`huddle-board ${isHuddleBoardVisible[0] ? 'huddle-board-active' : ''}`}
          onClick={() => toggleHuddleBoardCenterVisibility(0)}
        >
          <FontAwesomeIcon icon={faCircleInfo} className="huddle-board-project-view-icon" />
        </div>
        <a href={projectPlan} target="_blank" rel="noreferrer">
          <div
            className={`huddle-board ${isHuddleBoardVisible[1] ? 'huddle-board-active' : ''}`}
            // onClick={() => toggleHuddleBoardCenterVisibility(1)}
          >
            <FontAwesomeIcon icon={faChartGantt} className="huddle-board-project-view-icon" />
          </div>
        </a>
        <a href={projectRisks} target="_blank" rel="noreferrer">
          <div
            className={`huddle-board ${isHuddleBoardVisible[2] ? 'huddle-board-active' : ''}`}
            // onClick={() => toggleHuddleBoardCenterVisibility(2)}
          >
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="huddle-board-project-view-icon"
            />
          </div>
        </a>
        <a href={projectOpportunities} target="_blank" rel="noreferrer">
          <div
            className={`huddle-board ${isHuddleBoardVisible[3] ? 'huddle-board-active' : ''}`}
            // onClick={() => toggleHuddleBoardCenterVisibility(3)}
          >
            <FontAwesomeIcon icon={faClover} className="huddle-board-project-view-icon" />
          </div>
        </a>
        <div
          className={`huddle-board ${isHuddleBoardVisible[4] ? 'huddle-board-active' : ''}`}
          onClick={() => toggleHuddleBoardCenterVisibility(4)}
        >
          <FontAwesomeIcon icon={faHeartPulse} className="huddle-board-project-view-icon" />
        </div>
        <div
          className={`huddle-board ${isHuddleBoardVisible[15] ? 'huddle-board-active' : ''}`}
          onClick={() => toggleHuddleBoardCenterVisibility(15)}
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlassDollar}
            className="huddle-board-project-view-icon"
          />
        </div>
      </div>

      {/* Center Container */}
      <div className={`huddle-boards-center-container ${ isAnyHuddleBoardActive() ? 'huddle-board-is-active' : '' }`} >
        
        {/* Client & Patient Centiricity */}
        <div className={`huddle-board-center-left ${ isHuddleBoardVisible[0] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(0); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Client & Patient Centiricity') ? (
            <HuddleBoardContent
              title="Client & Patient Centiricity"
              huddleBoard={getFilteredHuddleBoard('Client & Patient Centiricity')}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Client & Patient Centiricity"
              simple={true}
            />
          )}
        </div>

        {/* Project Plan Key Milestones */}
        <div
          className={`huddle-board-center-left ${
            isHuddleBoardVisible[1] ? 'huddle-board-center-active' : ''
          }`}
        >
          Project Plan Key Milestones
        </div>

        {/* Risks */}
        <div
          className={`huddle-board-center-left ${
            isHuddleBoardVisible[2] ? 'huddle-board-center-active' : ''
          }`}
        >
          Risks
        </div>

        {/* Opportunities */}
        <div
          className={`huddle-board-center-left ${
            isHuddleBoardVisible[3] ? 'huddle-board-center-active' : ''
          }`}
        >
          Opportunities
        </div>

        {/* Team Pulse Check */}
        <div className={`huddle-board-center-left ${ isHuddleBoardVisible[4] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(4); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Team Pulse Check') ? (
            <HuddleBoardContent 
              labels={[5, 4, 3, 2, 1]}  
              title="Team Pulse Check" 
              huddleBoard={getFilteredHuddleBoard('Team Pulse Check')} 
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Team Pulse Check" simple={true} />
          )}
        </div>

        {/* Sponsor Report Out Project Health */}
        <div className={`huddle-board-center-left ${ isHuddleBoardVisible[15] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(15); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Sponsor Report') ? (
            <HuddleBoardContent 
              labels={['Green', 'Yellow', 'Red']}  
              title="Sponsor Report" 
              huddleBoard={getFilteredHuddleBoard('Sponsor Report')} 
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Sponsor Report" simple={true} />
          )}
        </div>

        {/* Weekly Priorities */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[5] ? 'huddle-board-center-active' : '' }`} >          
          <button onClick={() => { toggleHuddleBoardCenterVisibility(5); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Weekly Priorities') ? (
            <HuddleBoardContent 
              labels={['Complete', 'Green', 'Yellow', 'Red']}  
              title="Weekly Priorities" 
              huddleBoard={getFilteredHuddleBoard('Weekly Priorities')} 
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Weekly Priorities" simple={true} />
          )}
        </div>

        {/* Golden Moments */}
        <div className={`huddle-board-center-right ${isHuddleBoardVisible[6] ? 'huddle-board-center-active' : ''}`}>
          <button onClick={() => { toggleHuddleBoardCenterVisibility(6);}} className="back-button">
            &#8594;
          </button>
          {getFilteredHuddleBoard('Golden Moments') ? (
            <HuddleBoardContent title="Golden Moments" huddleBoard={getFilteredHuddleBoard('Golden Moments')}/>
          ) : (
            <HuddleBoardForm projectId={projectId} title="Golden Moments" simple={true} />
          )}
        </div>
        
        {/* Daily Agenda */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[7] ? 'huddle-board-center-active' : '' }`}>
          <button onClick={() => { toggleHuddleBoardCenterVisibility(7); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Daily Agenda') ? (
            <HuddleBoardContent 
              labels={['Completed', 'Not Completed']}  
              title="Daily Agenda" 
              huddleBoard={getFilteredHuddleBoard('Daily Agenda')} 
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Daily Agenda" simple={true} />
          )}
        </div>
        
        {/* Non-Tip Actions */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[8] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(8); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Non-Tip Actions') ? (
            <HuddleBoardContent title="Non-Tip Actions" huddleBoard={getFilteredHuddleBoard('Non-Tip Actions')} />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Non-Tip Actions" simple={true} />
          )}
        </div>

        {/* Attendance */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[9] ? 'huddle-board-center-active' : '' }`}>
          <button onClick={() => { toggleHuddleBoardCenterVisibility(9); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Attendance') ? (
            <HuddleBoardContent 
              labels={['Present', 'Absent']}  
              title="Attendance" 
              huddleBoard={getFilteredHuddleBoard('Attendance')} 
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Attendance" simple={true} />
          )}
        </div>

        {/* Safety */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[10] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(10); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Safety') ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="Safety"
              huddleBoard={getFilteredHuddleBoard('Safety')}
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Safety" />
          )}
        </div>

        {/* Quality */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[11] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(11); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Quality') ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="Quality"
              huddleBoard={getFilteredHuddleBoard('Quality')}
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Quality" />
          )}
        </div>

        {/* Cost */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[12] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(12); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Cost') ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="Cost"
              huddleBoard={getFilteredHuddleBoard('Cost')}
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Cost" />
          )}
        </div>
        
        {/* Delivery */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[14] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(14); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Delivery') ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="Delivery"
              huddleBoard={getFilteredHuddleBoard('Delivery')}
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="Delivery" />
          )}
        </div>

        {/* People */}
        <div className={`huddle-board-center-right ${ isHuddleBoardVisible[13] ? 'huddle-board-center-active' : '' }`} >
          <button onClick={() => { toggleHuddleBoardCenterVisibility(13); }} className="back-button" >
            &#8594;
          </button>
          {getFilteredHuddleBoard('People') ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="People"
              // updateHuddleBoard={updateHuddleBoard}
              huddleBoard={getFilteredHuddleBoard('People')}
            />
          ) : (
            <HuddleBoardForm projectId={projectId} title="People" />
          )}
        </div>

        {/* <div
          className={`huddle-board-center ${
            isHuddleBoardVisible[15] ? 'huddle-board-center-active' : ''
          }`}
        >
          <HuddleBoardForm projectId={projectId} />
        </div> */}
      </div>

      {/* Right Container */}
      <div
        className={`huddle-boards-right-container ${
          isAnyHuddleBoardActive() ? 'huddle-board-is-active' : ''
        }`}
      >
        {/* Daily/Weekly */}
        <div className="huddle-board-column">

          <div className="huddle-boards-group-title">Daily/Weekly</div>
          
          {/* Weekly Priorities */}
          <div className={`huddle-board ${isHuddleBoardVisible[5] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(5)} >
            <FontAwesomeIcon icon={faCalendarWeek} className="huddle-board-project-view-icon" />
          </div>
          
          {/* Daily Agenda */}
          <div className={`huddle-board ${isHuddleBoardVisible[7] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(7)} >
            <FontAwesomeIcon icon={faCalendarCheck} className="huddle-board-project-view-icon" />
          </div>

          {/* Non-Tip Actions */}
          <div className={`huddle-board ${isHuddleBoardVisible[8] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(8)} >
            <FontAwesomeIcon icon={faTableList} className="huddle-board-project-view-icon" />
          </div>

          {/* Golden Moments */}
          <div className={`huddle-board ${isHuddleBoardVisible[6] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(6)} >
            <FontAwesomeIcon icon={faMedal} className="huddle-board-project-view-icon" />
          </div>

          {/* Attendance */}
          <div className={`huddle-board ${isHuddleBoardVisible[9] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(9)} >
            <FontAwesomeIcon icon={faClipboardUser} className="huddle-board-project-view-icon" />
          </div>
        </div>

        {/* KPIs/SQCPD */}
        <div className="huddle-board-column">
          <div className="huddle-boards-group-title">KPIs</div>
          
          {/* Safety */}
          <div
            className={`huddle-board SQCPD-board ${
              isHuddleBoardVisible[10] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(10)}
          >
            S
          </div>

          {/* Quality */}
          <div
            className={`huddle-board SQCPD-board ${
              isHuddleBoardVisible[11] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(11)}
          >
            Q
          </div>

          {/* Cost */}
          <div
            className={`huddle-board SQCPD-board ${
              isHuddleBoardVisible[12] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(12)}
          >
            C
          </div>

          {/* Delivery */}
          <div
            className={`huddle-board SQCPD-board ${
              isHuddleBoardVisible[14] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(14)}
          >
            D
          </div>

          {/* People */}
          <div
            className={`huddle-board SQCPD-board ${
              isHuddleBoardVisible[13] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(13)}
          >
            P
          </div>

          {/* Add new */}
          {/* <div
            className={`huddle-board ${isHuddleBoardVisible[15] ? 'huddle-board-active' : ''}`}
            onClick={() => toggleHuddleBoardCenterVisibility(15)}
          >
            Add new
          </div> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(HuddleBoards);
