import React, { useEffect, useState, useMemo } from 'react';
import MetricDataDaily from '../DailyLayout/MetricDataDaily';
// import StatusCard from './StatusCard/StatusCard';
import { month } from '../../../constants/index';
import { normalizeYearsDataWithMaster } from '../../../constants/functions';
import { Line } from 'react-chartjs-2';
import Loader from '../../Loader/Loader';
import Chart from 'chart.js/auto';
import './Metrica.css';

const MetricDaily = ({ huddleBoard, title, updateHuddleBoard, labels, selectedDate }) => {
  const currentDate = useMemo(() => selectedDate || new Date(), [selectedDate]);
  const currentYear = useMemo(() => currentDate.getFullYear().toString(), [currentDate]);
  const currentMonth = useMemo(() => month[currentDate.getMonth()], [currentDate]);
  
  //Chart
  const formatChartData = (huddleBoardData) => {
    if (huddleBoardData) {      
      const data = normalizeYearsDataWithMaster(huddleBoard?.data, selectedDate)[0].years[currentYear][currentMonth];      

      // Count values for each month
      let days = [];
      let counts = data.map((day, index) => {   
        days.push(index+1);
        if (day.length > 0) {
          if (title === 'Daily Agenda') {
            return {
              x: index+1,
              y: (day.filter((d) => d.status?.includes('completed')).length / day.length) * 100
            };
          }

          if (title === 'Attendance') {
            return {
              x: index+1,
              y: (day.filter((d) => d.status?.includes('present')).length / day.length) * 100
            };
          }
          
        } else {
          return {
            x: index+1,
            y: 0
          };
        }
      });

      // Prepare chart data
      let newChartData = {        
        labels: days,
        datasets: [
          {
            label: labels[0],
            data: counts,
            backgroundColor: '#4CAF50',
            borderColor: '#4CAF50',
          }
        ],
      };

      // Update state to re-render the chart
      return newChartData;
    }
  };

  /* if (isLoading) {
    return <Loader />;
  }

  if (huddleBoardError) {
    return <div>Error: {huddleBoardError}</div>;
  } */

  return (
    <>
      <div className="huddle-board-content-metric">
        <div className="control-metrics-data">
          <h3>Selected day's metrics</h3>
          <MetricDataDaily huddleBoard={huddleBoard} labels={labels} title={title} selectedDate={selectedDate} />
        </div>
        <div className=" huddle-board-content-graph chart-container">
          {huddleBoard ? (
            <Line
              data={formatChartData(huddleBoard)}
              options={{
                plugins: {
                  legend: {
                    position: 'top',
                    font: {
                      size: 48
                    }
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    type: 'linear',
                    min: 0,
                    max: 100,
                    beginAtZero: true,
                  },
                },
              }}
            />
          ) : (
            <div>Loading chart...</div>
          )}
        </div>        
      </div>
    </>
  );
};
export default MetricDaily;
